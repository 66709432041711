<template>
  <v-menu
    ref="menu"
    v-model="show"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    :left="left"
    :bottom="bottom"
    :rounded="rounded"
  >
    <template v-if="icon" v-slot:activator="{ on }">
      <v-icon v-on="on" :loading="loading">
        {{ range ? "mdi-calendar-multiple" : "mdi-calendar" }}
      </v-icon>
    </template>
    <template v-else v-slot:activator="{ on }">
      <validation-provider
        :vid="name"
        :name="label != null ? label : $t('date')"
        :rules="rules"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-on="on"
          :name="name"
          :value="
            date != null && date.length == 2
              ? formatDate(date[0]) + ' - ' + formatDate(date[1])
              : showTodayAsAsap && date != null && isToday(date)
              ? $t('asap')
              : date != null
              ? formatDate(date)
              : showTodayAsAsap
              ? $t('asap')
              : ''
          "
          :label="label != null ? label : $t('date')"
          :hint="label != null ? hint : ''"
          :error-messages="errors"
          :success="noForm ? null : dirty && valid"
          :class="noForm ? null : classes"
          :disabled="disabled"
          :prepend-icon="
            hideIcon ? null : range ? 'mdi-calendar-multiple' : 'mdi-calendar'
          "
          :clearable="clearable"
          :loading="loading"
          readonly
          @click:clear="$emit('input', null)"
        />
      </validation-provider>
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      @change="
        show = !range || (date != null && date.length == 2) ? false : true
      "
      :allowed-dates="dateAllowed"
      :show-current="showCurrent"
      :range="range"
      :min="min != null ? min : null"
      :max="max != null ? max : null"
      :first-day-of-week="
        firstDayOfWeek != null
          ? firstDayOfWeek
          : $store.state.locale == 'en'
          ? 0
          : 1
      "
      :locale="$store.state.locale"
      :locale-first-day-of-year="$store.state.locale == 'en' ? 0 : 4"
      :active-picker="activePicker2 || undefined"
      no-title
      scrollable
    >
      <v-spacer />
      <v-btn text color="primary" @click="show = false" v-if="showCancelBtn">
        $t("cancel")
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(date)"
        v-if="showOkBtn"
      >
        $t("ok")
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="
          date = todayString();
          $refs.menu.save(date);
        "
        v-if="showTodayBtn"
      >
        {{ todayBtnText != null ? todayBtnText : $t("today") }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";

export default {
  name: "DatePicker",
  mixins: [formatDate],
  props: {
    value: {
      type: [String, Array],
    },
    name: {
      type: String,
      default: "wishdate",
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: "",
    },
    today: {
      type: Boolean,
      default: false,
    },
    allowedDates: {
      type: Function,
      default: null,
    },
    clearable: {
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    sortRange: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    showCurrent: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    activePicker: {
      type: String,
      default: null,
    },
    firstDayOfWeek: {
      type: [Number, String],
      default: null,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    noForm: {
      type: Boolean,
      default: false,
    },
    showTodayAsAsap: {
      type: Boolean,
      default: false,
    },
    showCancelBtn: {
      type: Boolean,
      default: false,
    },
    showOkBtn: {
      type: Boolean,
      default: false,
    },
    showTodayBtn: {
      type: Boolean,
      default: false,
    },
    todayBtnText: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    show: false,
    date: "",
    activePicker2: null,
  }),
  watch: {
    show(value) {
      var that = this;
      if (value && this.activePicker)
        setTimeout(() => (that.activePicker2 = that.activePicker));
    },
    value: {
      immediate: true,
      handler(value) {
        // set v-model to today if not set
        if ((value == null || value == "") && this.today && this.range) {
          this.$emit("input", [this.todayString(), this.todayString()]);
        } else if ((value == null || value == "") && this.today) {
          this.$emit("input", this.todayString());
        }
        this.date = value;
      },
    },
    date(value) {
      if (
        value != null &&
        value.length > 1 &&
        this.range &&
        this.sortRange &&
        this.$moment(value[1]).isBefore(value[0])
      ) {
        this.date = [value[1], value[0]];
        this.$emit("input", this.date);
      } else if (value != null && (!this.range || value.length == 2))
        this.$emit("input", value);
    },
  },
  methods: {
    isToday(date) {
      return this.$moment(date).isSame(this.$moment(), "day");
    },
    todayString() {
      return this.$moment(new Date()).format("YYYY-MM-DD");
    },
    // TODO: allow Array of dates as well
    dateAllowed(date) {
      if (this.allowedDates) return this.allowedDates(date);
      else if (this.today) {
        const today = this.todayString();
        return (
          this.$moment(today).isSame(date, "day") ||
          this.$moment(today).isBefore(date)
        );
      }
      return true;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "date": "Date",
    "asap": "asap.",
    "cancel": "Cancel",
    "ok": "OK",
    "today": "Today"
  }, 
  "de": {
    "date": "Datum",
    "asap": "sofort",
    "cancel": "Abbrechen",
    "ok": "OK",
    "today": "Heute"
  }
}
</i18n>
