<template>
  <div>
    <v-row>
      <v-col cols="3">
        <validation-provider
          v-if="!zipHide"
          :vid="prefixName + zipName"
          :name="zipLabel != null ? zipLabel : $t('zip')"
          :rules="
            (zipRules != null ? zipRules + '|' : '') +
              (zipRequired ? 'required|' : '') +
              'min:4|max:4|numeric'
          "
          v-slot="{ valid, dirty, errors, classes }"
        >
          <v-combobox
            :ref="zipName"
            :name="prefixName + zipName"
            :search-input.sync="zipSearch"
            :value="zipValue"
            :items="zipList"
            :label="zipLabel != null ? zipLabel : $t('zip')"
            :hint="zipHint != null ? zipHint : ''"
            :error-messages="errors"
            :success="dirty && valid"
            :loading="zipLoading"
            :disabled="disabled || zipDisabled"
            item-text="postcode"
            item-value="id"
            @input="inputZip"
            @update:search-input="inputZipTimer"
            @click:clear="
              zipValue = '';
              zipId = null;
              zipSearch = null;
              zipList = [];
              zipAutoComplete = true;
            "
            :maxlength="zipMaxLength"
            :class="classes"
            autocomplete="none"
            type="number"
            return-object
            :clearable="!isMobile"
            v-disabled-icon-focus
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-if="data.item.id != null"
                  v-html="
                    maskSearchValue(
                      data.item.postcode + ' - ' + data.item.name,
                      zipSearch
                    )
                  "
                />
                <v-list-item-title
                  v-else
                  v-html="
                    maskSearchValue(
                      data.item.postcode,
                      zipSearch,
                      $t('your input')
                    )
                  "
                />
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item v-if="zipSearch">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="$t('ZipNotFound', { search: zipSearch })"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </validation-provider>
      </v-col>
      <v-col cols="9">
        <validation-provider
          v-if="!cityHide"
          :vid="prefixName + cityName"
          :name="cityLabel != null ? cityLabel : $t('city')"
          :rules="
            (cityRules != null ? cityRules + '|' : '') +
              (cityRequired ? 'required|' : '') +
              '|max:100'
          "
          v-slot="{ valid, dirty, errors, classes }"
        >
          <v-combobox
            :ref="cityName"
            :name="prefixName + cityName"
            :search-input.sync="citySearch"
            :value="cityValue"
            :items="cityList"
            :label="cityLabel != null ? cityLabel : $t('city')"
            :hint="cityHint != null ? cityHint : ''"
            :error-messages="errors"
            :success="dirty && valid"
            :loading="cityLoading"
            :disabled="disabled || cityDisabled"
            item-text="name"
            item-value="id"
            @input="inputCity"
            @update:search-input="inputCityTimer"
            @click:clear="
              cityValue = '';
              cityId = null;
              citySearch = null;
              cityList = [];
              cityAutoComplete = true;
            "
            :maxlength="cityMaxLength"
            :class="classes"
            autocomplete="none"
            return-object
            :clearable="!isMobile"
            v-disabled-icon-focus
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-if="data.item.id != null"
                  v-html="
                    maskSearchValue(
                      data.item.postcode + ' - ' + data.item.name,
                      citySearch
                    )
                  "
                />
                <v-list-item-title
                  v-else
                  v-html="
                    maskSearchValue(
                      data.item.name,
                      citySearch,
                      $t('your input')
                    )
                  "
                />
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item v-if="citySearch">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="$t('CityNotFound', { search: citySearch })"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <validation-provider
          v-if="!streetHide"
          :vid="prefixName + streetName"
          :name="streetLabel != null ? streetLabel : $t('street')"
          :rules="(streetRules != null ? streetRules + '|' : '') + 'max:100'"
          v-slot="{ valid, dirty, errors, classes }"
        >
          <v-combobox
            :ref="streetName"
            :name="prefixName + streetName"
            :search-input.sync="streetSearch"
            :value="streetValue"
            :items="streetList"
            :label="streetLabel != null ? streetLabel : $t('street')"
            :hint="streetHint != null ? streetHint : ''"
            :error-messages="errors"
            :success="dirty && valid"
            :loading="streetLoading"
            :disabled="disabled || streetDisabled"
            item-text="name"
            item-value="id"
            @input="inputStreet"
            @update:search-input="inputStreetTimer"
            @click:clear="
              streetValue = '';
              streetId = null;
              streetSearch = null;
              streetList = [];
              streetAutoComplete = true;
            "
            :maxlength="streetMaxLength"
            :class="classes"
            autocomplete="none"
            return-object
            :clearable="!isMobile"
            v-disabled-icon-focus
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-if="data.item.id != null"
                  v-html="
                    maskSearchValue(
                      data.item.place.postcode +
                        ' - ' +
                        data.item.place.name +
                        ' - ' +
                        data.item.name,
                      streetSearch
                    )
                  "
                />
                <v-list-item-title
                  v-else
                  v-html="
                    maskSearchValue(
                      data.item.name,
                      streetSearch,
                      $t('your input')
                    )
                  "
                />
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item v-if="streetSearch">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="$t('StreetNotFound', { search: streetSearch })"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </validation-provider>
      </v-col>
      <v-col cols="3">
        <validation-provider
          v-if="!streetNumberHide"
          :vid="prefixName + streetNumberName"
          :name="streetNumberLabel != null ? streetNumberLabel : $t('number')"
          :rules="
            (streetNumberRules != null ? streetNumberRules + '|' : '') +
              'max:10'
          "
          v-slot="{ valid, dirty, errors, classes }"
        >
          <v-combobox
            :ref="streetNumberName"
            :name="prefixName + streetNumberName"
            :search-input.sync="streetNumberSearch"
            :value="streetNumberValue"
            :items="streetNumberList"
            :label="
              streetNumberLabel != null ? streetNumberLabel : $t('number')
            "
            :hint="streetNumberHint != null ? streetNumberHint : ''"
            :error-messages="errors"
            :success="dirty && valid"
            :loading="streetNumberLoading"
            :disabled="disabled || streetNumberDisabled"
            @input="inputStreetNumber"
            @update:search-input="inputStreetNumberTimer"
            @click:clear="
              streetNumberValue = '';
              streetNumberSearch = null;
              streetNumberList = [];
              streetNumberAutoComplete = true;
            "
            :maxlength="streetNumberMaxLength"
            :class="classes"
            autocomplete="none"
            :clearable="!isMobile"
            v-disabled-icon-focus
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="maskSearchValue(data.item, streetNumberSearch)"
                />
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item v-if="streetNumberSearch">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="
                      $t('StreetNumberNotFound', { search: streetNumberSearch })
                    "
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { config } from "@/config";
import isMobile from "@/utils/mixins/isMobile";

const client = axios.create({
  baseURL: config.addressBackendUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: config.addressBackendTimeout || 3000,
});

client.interceptors.request.use(
  function(cfg) {
    cfg.headers["Authorization"] = "Bearer " + config.addressBackendToken;
    return cfg;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default {
  name: "SwissAddressAutocomplete",
  props: {
    value: {},
    zipName: {
      type: String,
      default: "zip",
    },
    zipLabel: {
      type: String,
      default: null,
    },
    zipHint: {
      type: String,
      default: null,
    },
    zipRules: {
      type: String,
      default: null,
    },
    zipMaxLength: {
      type: [Number, String],
      default: 4,
    },
    zipDisabled: {
      type: Boolean,
      default: false,
    },
    zipHide: {
      type: Boolean,
      default: false,
    },
    zipRequired: {
      type: Boolean,
      default: true,
    },
    cityName: {
      type: String,
      default: "city",
    },
    cityLabel: {
      type: String,
      default: null,
    },
    cityHint: {
      type: String,
      default: null,
    },
    cityRules: {
      type: String,
      default: null,
    },
    cityMaxLength: {
      type: [Number, String],
      default: 100,
    },
    cityDisabled: {
      type: Boolean,
      default: false,
    },
    cityHide: {
      type: Boolean,
      default: false,
    },
    cityRequired: {
      type: Boolean,
      default: true,
    },
    streetName: {
      type: String,
      default: "street",
    },
    streetLabel: {
      type: String,
      default: null,
    },
    streetHint: {
      type: String,
      default: null,
    },
    streetRules: {
      type: String,
      default: null,
    },
    streetMaxLength: {
      type: [Number, String],
      default: 100,
    },
    streetDisabled: {
      type: Boolean,
      default: false,
    },
    streetHide: {
      type: Boolean,
      default: false,
    },
    streetNumberName: {
      type: String,
      default: "street_number",
    },
    streetNumberLabel: {
      type: String,
      default: null,
    },
    streetNumberHint: {
      type: String,
      default: null,
    },
    streetNumberRules: {
      type: String,
      default: null,
    },
    streetNumberMaxLength: {
      type: [Number, String],
      default: 10,
    },
    streetNumberDisabled: {
      type: Boolean,
      default: false,
    },
    streetNumberHide: {
      type: Boolean,
      default: false,
    },
    prefixName: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [isMobile],
  data: () => ({
    alive: true,
    zipSearch: null,
    zipValue: null,
    zipId: null,
    zipList: [],
    zipLoading: false,
    zipAutoComplete: true,
    zipInputTimer: null,
    citySearch: null,
    cityValue: null,
    cityId: null,
    cityList: [],
    cityLoading: false,
    cityAutoComplete: true,
    cityInputTimer: null,
    streetSearch: null,
    streetValue: null,
    streetId: null,
    streetList: [],
    streetLoading: false,
    streetAutoComplete: true,
    streetInputTimer: null,
    streetNumberSearch: null,
    streetNumberValue: null,
    streetNumberList: [],
    streetNumberLoading: false,
    streetNumberInputTimer: null,
    timer: 500,
  }),
  watch: {
    zipSearch(value) {
      this.searchZips(value);
    },
    citySearch(value) {
      this.searchCities(value);
    },
    streetSearch(value) {
      this.searchStreets(value, this.zipId);
    },
    streetNumberSearch(value) {
      this.searchStreetNumbers(value);
    },
    value: {
      immediate: true,
      handler(value) {
        this.zipValue =
          value && value[this.zipName] != null ? value[this.zipName] : null;
        if (this.zipSearch == null) this.zipSearch = this.zipValue;
        this.cityValue =
          value && value[this.cityName] != null ? value[this.cityName] : null;
        if (this.citySearch == null) this.citySearch = this.cityValue;
        this.streetValue =
          value && value[this.streetName] != null
            ? value[this.streetName]
            : null;
        if (this.streetSearch == null) this.streetSearch = this.streetValue;
        this.streetNumberValue =
          value && value[this.streetNumberName] != null
            ? value[this.streetNumberName]
            : null;
        if (this.streetNumberSearch == null)
          this.streetNumberSearch = this.streetNumberValue;
      },
    },
  },
  methods: {
    isString(obj) {
      return Object.prototype.toString.call(obj) === "[object String]";
    },
    validValue(value) {
      return (
        value != null &&
        (typeof value === "string" || value instanceof String) &&
        value.trim() != ""
      );
    },
    searchZips(value) {
      //console.log("searchZips", value);
      var that = this;
      var zipList = [];
      if (this.validValue(value) && this.alive && !this.zipLoading) {
        this.zipLoading = true;
        return client
          .get("postcodes", {
            params: {
              postcode: value,
            },
          })
          .then((response) => {
            // add filter here because v-combobox filter will not work
            // with out "update:search-input" hack
            that.zipList = response.data.filter(function(item) {
              return (
                !that.zipSearch ||
                (item.postcode != null &&
                  item.postcode
                    .toString()
                    .indexOf(that.zipSearch.toLowerCase()) > -1)
              );
            });
          })
          .catch(function(error) {
            that.alive = false;
          })
          .finally(function() {
            that.zipLoading = false;
          });
      }
    },
    searchCities(value) {
      //console.log("searchCities", value);
      var that = this;
      if (this.validValue(value) && this.alive && !this.cityLoading) {
        this.cityLoading = true;
        return client
          .get("places", {
            params: {
              name: value,
              // all: false
            },
          })
          .then((response) => {
            // add filter here because v-combobox filter will not work
            // with out "update:search-input" hack
            that.cityList = response.data.filter(function(item) {
              return (
                !that.citySearch ||
                item.name.toLowerCase().indexOf(that.citySearch.toLowerCase()) >
                  -1
              );
            });
          })
          .catch(function(error) {
            that.alive = false;
          })
          .finally(function() {
            that.cityLoading = false;
          });
      }
    },
    searchStreets(value, place) {
      //console.log("searchStreets", value, place);
      var that = this;
      var streetList = [];
      if (
        this.validValue(value || place) &&
        this.alive &&
        !this.streetLoading
      ) {
        this.streetLoading = true;
        return client
          .get("streets", {
            params: {
              name: value,
              place_id: place,
            },
          })
          .then((response) => {
            // add filter here because v-combobox filter will not work
            // with out "update:search-input" hack
            that.streetList = response.data.filter(function(item) {
              return (
                !that.streetSearch ||
                item.name
                  .toLowerCase()
                  .indexOf(that.streetSearch.toLowerCase()) > -1
              );
            });
          })
          .catch(function(error) {
            that.alive = false;
          })
          .finally(function() {
            that.streetLoading = false;
          });
      }
    },
    searchStreetNumbers(value) {
      //console.log("searchStreetNumbers", value), this.streetId;
      var that = this;
      var streetNumberList = [];
      if (this.streetId && this.alive && !this.streetNumberLoading) {
        this.streetNumberLoading = true;
        return client
          .get("streets/" + this.streetId)
          .then((response) => {
            // add filter here because v-combobox filter will not work
            // with out "update:search-input" hack
            that.streetNumberList = response.data.house_numbers.filter(function(
              item
            ) {
              return (
                !that.streetNumberSearch ||
                item
                  .toLowerCase()
                  .indexOf(that.streetNumberSearch.toLowerCase()) > -1
              );
            });
          })
          .catch(function(error) {
            that.alive = false;
          })
          .finally(function() {
            that.streetNumberLoading = false;
          });
      }
    },
    getValue() {
      return {
        ...this.value,
        [this.zipName]: this.zipValue,
        [this.cityName]: this.cityValue,
        [this.streetName]: this.streetValue,
        [this.streetNumberName]: this.streetNumberValue,
      };
    },
    inputZip(item) {
      //console.log("inputZip: ", item);
      if (item != null && this.isString(item)) {
        this.zipId = this.zipValue != item ? null : this.zipId;
        this.zipValue = item;
      } else if (item == null || item.id == null) {
        this.zipAutoComplete = Boolean(item === null);
        this.zipId = null;
        this.zipValue = item;
      } else {
        this.zipId = item.id;
        this.zipValue = item.postcode;
        if (this.cityAutoComplete) {
          this.cityId = item.id;
          this.cityValue = item.name;
          this.cityList = [
            {
              postcode: item.postcode,
              name: item.name,
              id: item.id,
            },
          ];
        }
        if (this.alive)
          this.searchStreets(this.streetSearch, this.cityId || this.zipId);
        this.$refs[this.streetName].focus();
      }
      this.$emit("input", this.getValue());
    },
    inputZipTimer(item) {
      var that = this;
      clearTimeout(this.zipInputTimer);
      this.zipInputTimer = setTimeout(function() {
        that.inputZip(item);
      }, this.timer);
    },
    inputCity(item) {
      //console.log("inputCity: ", item);
      if (item != null && this.isString(item)) {
        this.citytId = this.cityValue != item ? null : this.cityId;
        this.cityValue = item;
      } else if (item == null || item.id == null) {
        this.cityAutoComplete = Boolean(item === null);
        this.cityId = null;
        this.cityValue = item;
      } else {
        this.cityId = item.id;
        this.cityValue = item.name;
        if (this.zipAutoComplete) {
          this.zipId = item.id;
          this.zipValue = item.postcode;
          this.zipList = [
            {
              postcode: item.postcode,
              name: item.name,
              id: item.id,
            },
          ];
        }
        if (this.alive) this.searchStreets(this.streetSearch, item.id);
        this.$refs[this.streetName].focus();
      }
      this.$emit("input", this.getValue());
    },
    inputCityTimer(item) {
      var that = this;
      clearTimeout(this.cityInputTimer);
      this.cityInputTimer = setTimeout(function() {
        that.inputCity(item);
      }, this.timer);
    },
    inputStreet(item) {
      //console.log("inputStreet: ", item);
      if (item != null && this.isString(item)) {
        this.streetId = this.streetValue != item ? null : this.streetId;
        this.streetValue = item;
      } else if (item == null || item.id == null) {
        this.streetAutoComplete = Boolean(item === null);
        this.streetId = null;
        this.streetValue = item;
      } else {
        this.streetId = item.id;
        this.streetValue = item.name;
        if (item.place != null) {
          if (this.cityAutoComplete) {
            this.cityId = item.place.id;
            this.cityValue = item.place.name;
            this.cityList = [
              {
                postcode: item.place.postcode,
                name: item.place.name,
                id: item.place.id,
              },
            ];
          }
          if (this.zipAutoComplete) {
            this.zipId = item.place.id;
            this.zipValue = item.place.postcode;
            this.zipList = [
              {
                postcode: item.place.postcode,
                name: item.place.name,
                id: item.place.id,
              },
            ];
          }
        }
        if (this.alive) this.searchStreetNumbers(this.streetNumberSearch);
        this.$refs[this.streetNumberName].focus();
      }
      this.$emit("input", this.getValue());
    },
    inputStreetTimer(item) {
      var that = this;
      clearTimeout(this.streetInputTimer);
      this.streetInputTimer = setTimeout(function() {
        that.inputStreet(item);
      }, this.timer);
    },
    inputStreetNumber(number) {
      //console.log("inputStreetNumber: ", number);
      this.streetNumberValue = number;
      this.$emit("input", this.getValue());
    },
    inputStreetNumberTimer(item) {
      var that = this;
      clearTimeout(this.streetNumberInputTimer);
      this.streetNumberInputTimer = setTimeout(function() {
        that.inputStreetNumber(item);
      }, this.timer);
    },
    maskSearchValue(value, search, suffix = "") {
      value = value || "";
      search = search || "";
      suffix = suffix || "";
      var result = value;
      if (value != "" && search != "") {
        var idx = value.toLowerCase().indexOf(search.toLowerCase());
        var p0,
          p1,
          p2 = "";
        if (idx >= 0) {
          p0 = value.slice(0, idx);
          p1 = value.slice(idx, idx + search.length);
          p2 = value.slice(idx + search.length, value.length);
          result = p0 + '<span class="list-item-mask">' + p1 + "</span>" + p2;
        }
      }
      if (this.validValue(result) && suffix != "")
        result = "<i>" + result + " - " + suffix + "</i>";
      return result;
    },
  },
};
</script>

<style>
.list-item-mask {
  text-decoration: underline;
}
</style>

<i18n>
{
  "en": {
    "zip": "Zip",
    "city": "City",
    "street": "Street",
    "number": "Number",
    "your input": "Your input",
    "ZipNotFound": "ZIP <strong>{search}</strong> not found.",
    "CityNotFound": "City <strong>{search}</strong> not found.",
    "StreetNotFound": "Street <strong>{search}</strong> not found.",
    "StreetNumberNotFound": "Number <strong>{search}</strong> not found."
 },
  "de": {
    "zip": "PLZ",
    "city": "Ort",
    "street": "Strasse",
    "number": "Nummer",
    "your input": "Ihre Eingabe",
    "ZipNotFound": "PLZ <strong>{search}</strong> nicht gefunden.",
    "CityNotFound": "Stadt <strong>{search}</strong> nicht gefunden.",
    "StreetNotFound": "Strasse <strong>{search}</strong> nicht gefunden.",
    "StreetNumberNotFound": "Nummer <strong>{search}</strong> nicht gefunden."
  }
}
</i18n>
