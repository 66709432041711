/**
 * re-format AccessChecker (services/internet/check) response
 * @param {data} response
 * @param {provider} not used
 */

export default function (data, provider) {
    var resultIdx = 0;
    return data.map(function (result) {
        var serviceIdx = 0;
        resultIdx += 1;
        return {
            id: resultIdx - 1,
            provider: result.provider,
            services: result.available_profiles.map(function (service) {
                var profileIdx = 0;
                var otoIdx = 0;
                serviceIdx += 1;
                return {
                    id: serviceIdx - 1,
                    name: service.service_type,
                    maxDownStream: service.max_downstream,
                    maxUpStream: service.max_upstream,
                    address: result.additional_data.address != null ? result.additional_data.address : {},
                    otos: result.additional_data.oto_ids != null ? result.additional_data.oto_ids.map(function (oto) {
                        otoIdx += 1;
                        return {
                            ...oto,
                            id: otoIdx - 1
                        }
                    }) : [],
                    profiles: result.profiles.map(function (profile) {
                        profileIdx += 1;
                        return { ...profile, id: profileIdx - 1 };
                    }),
                    timeSlots: []
                };
            })
        };
    });
}