<template>
  <div>
    <v-progress-linear :active="checking" indeterminate />
    <v-expansion-panels v-model="checkIdx">
      <v-expansion-panel>
        <v-expansion-panel-header class="title">
          {{ $t("address") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card elevation="0" v-if="checkIdx == 0">
            <v-card-text>
              <swiss-address-autocomplete
                v-if="checkIdx == 0"
                v-model="address"
                :zip-required="checkIdx == 0"
                :city-required="checkIdx == 0"
                :street-hide="showBuilding"
                :street-number-hide="showBuilding"
              />
              <v-row v-if="showBuilding">
                <v-col cols="12">
                  <validation-provider
                    vid="building"
                    :name="$t('building')"
                    rules="max:100"
                    :disabled="checkIdx != 0"
                    v-slot="{ valid, dirty, errors, classes }"
                  >
                    <v-text-field
                      name="building"
                      v-model="address.building"
                      :label="$t('building')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      maxlength="100"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-switch
                    v-model="showBuilding"
                    :label="$t('no street name')"
                  />
                </v-col>
                <v-col cols="8" />
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="title">
          {{ $t("extension number") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card elevation="0" v-if="checkIdx == 1">
            <v-card-text>
              <validation-provider
                vid="number"
                :name="$t('extension number')"
                rules="required|between:0100000001,0199999999"
                :disabled="checkIdx != 1"
                v-slot="{ valid, dirty, errors, classes }"
              >
                <v-text-field
                  name="number"
                  v-model="number"
                  :label="$t('extension number')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :class="classes"
                  type="number"
                  :clearable="!isMobile"
                />
              </validation-provider>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="title">
          {{ $t("OTO ID") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card elevation="0" v-if="checkIdx == 2">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    vid="oto"
                    :name="$t('OTO')"
                    :rules="{
                      required: true,
                      regex:
                        /^([A-Z0-9]\.[0-9]{3}\.[0-9]{3}\.[0-9]{3}|([WP]|NU)P[0-9a-z_. \-]+|OV[0-9]{4})/,
                    }"
                    :disabled="checkIdx != 2"
                    v-slot="{ valid, dirty, errors, classes }"
                  >
                    <v-text-field
                      name="oto"
                      v-model="oto"
                      :label="$t('OTO')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :clearable="!isMobile"
                    />
                  </validation-provider>
                </v-col>
                <!-- disallow customers to set OTO plug number
                <v-col cols="3">
                  <validation-provider
                    vid="plug"
                    :name="$t('plug')"
                    rules="between:1,4"
                    :disabled="checkIdx != 2"
                    v-slot="{ valid, dirty, errors, classes }"
                  >
                    <v-text-field
                      name="plug"
                      v-model="plug"
                      :label="$t('plug')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      type="number"
                      :clearable="!isMobile"
                    />
                  </validation-provider>
                </v-col>
                -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="title">
          {{ $t("binding ID") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card elevation="0" v-if="checkIdx == 3">
            <v-card-text>
              <validation-provider
                vid="bindingid"
                :name="$t('binding ID')"
                :rules="{
                  required: true,
                  min: 3,
                  max: 15,
                  regex: /^AC\d(\.\d{3}){3}$/,
                }"
                :disabled="checkIdx != 3"
                v-slot="{ valid, dirty, errors, classes }"
              >
                <v-text-field
                  name="bindingid"
                  v-model="bindingId"
                  :label="$t('binding ID')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :class="classes"
                  :clearable="!isMobile"
                />
              </validation-provider>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="title">
          {{ $t("Swisscom predecessor DB") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="checkIdx == 4">
          <predecessor-lookup
            v-if="predecessorLookup"
            :address="predecessorAddress"
            @close="predecessorLookup = false"
            @select="selectPredecessor($event)"
          />

          <validation-observer v-else v-slot="{ invalid }">
            <v-tabs v-model="predecessorLookupTab">
              <v-tab>{{ $t("Address") }}</v-tab>
              <v-tab>{{ $t("Phonenumber") }}</v-tab>
            </v-tabs>

            <v-card elevation="0">
              <v-card-text>
                <v-tabs-items v-model="predecessorLookupTab">
                  <v-tab-item>
                    <swiss-address-autocomplete
                      v-if="predecessorLookupTab === 0"
                      v-model="predecessorAddress"
                      zip-required
                      city-required
                      :street-hide="showBuildingPredecessor"
                      :street-number-hide="showBuildingPredecessor"
                    />

                    <v-row v-if="showBuildingPredecessor">
                      <v-col cols="12">
                        <validation-provider
                          vid="building"
                          :name="$t('building')"
                          rules="max:100"
                          :disabled="checkIdx != 0"
                          v-slot="{ valid, dirty, errors, classes }"
                        >
                          <v-text-field
                            name="building"
                            v-model="predecessorAddress.building"
                            :label="$t('building')"
                            :error-messages="errors"
                            :success="dirty && valid"
                            :class="classes"
                            maxlength="100"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-switch
                          v-model="showBuildingPredecessor"
                          :label="$t('no street name')"
                        />
                      </v-col>
                      <v-col cols="8" />
                    </v-row>
                  </v-tab-item>

                  <v-tab-item>
                    <validation-provider
                      v-if="predecessorLookupTab === 1"
                      vid="phonenumberswisscomdb"
                      :name="$t('Phonenumber')"
                      rules="nsn_tel|required"
                      :disabled="checkIdx != 0"
                      v-slot="{ valid, dirty, errors, classes }"
                    >
                      <v-text-field
                        name="phonenumberswisscomdb"
                        v-model="predecessorAddress.phone_nr"
                        :label="$t('Phonenumber')"
                        :error-messages="errors"
                        :success="dirty && valid"
                        :class="classes"
                        :clearable="!isMobile"
                      />
                    </validation-provider>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  @click="predecessorLookup = true"
                  :disabled="invalid || predecessorLookup"
                  :loading="predecessorLookup"
                  text
                >
                  {{ $t("search") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </validation-observer>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import SwissAddressAutocomplete from "@/components/basics/SwissAddressAutocomplete";
import PredecessorLookup from "@/components/orders/internet/PredecessorLookup";
import isMobile from "@/utils/mixins/isMobile";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("inetOrder");

export default {
  name: "CheckerQuery",
  components: {
    SwissAddressAutocomplete,
    PredecessorLookup,
  },
  mixins: [isMobile],
  props: {
    checking: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showBuilding: false,
    showBuildingPredecessor: false,
    predecessorLookupTab: 0,
    predecessorLookup: false,
    predecessorAddress: {
      phone_nr: "",
      company: "",
      first_name: "",
      last_name: "",
      zip: "",
      city: "",
      street: "",
      street_number: "",
      building: "",
    },
  }),
  computed: {
    checkIdx: {
      ...mapState({ get: "checkIdx" }),
      ...mapMutations({ set: "setCheckIdx" }),
    },
    address: {
      ...mapState({ get: "address" }),
      ...mapMutations({ set: "setAddress" }),
    },
    number: {
      ...mapState({ get: "number" }),
      ...mapMutations({ set: "setNumber" }),
    },
    oto: {
      ...mapState({ get: "oto" }),
      ...mapMutations({ set: "setOto" }),
    },
    plug: {
      ...mapState({ get: "plug" }),
      ...mapMutations({ set: "setPlug" }),
    },
    bindingId: {
      ...mapState({ get: "bindingId" }),
      ...mapMutations({ set: "setBindingId" }),
    },
  },
  watch: {
    // sync building with store
    "address.building"() {
      this.address = { ...this.address };
    },
    showBuilding(value) {
      if (value)
        this.address = {
          ...this.address,
          street: "",
          street_number: "",
        };
      else
        this.address = {
          ...this.address,
          building: "",
        };
    },
    showBuildingPredecessor(value) {
      if (value)
        this.predecessorAddress = {
          ...this.predecessorAddress,
          street: "",
          street_number: "",
        };
      else
        this.predecessorAddress = {
          ...this.predecessorAddress,
          building: "",
        };
    },
    predecessorLookupTab(value) {
      this.predecessorAddress = {
        phone_nr: "",
        company: "",
        first_name: "",
        last_name: "",
        zip: "",
        city: "",
        street: "",
        street_number: "",
        building: "",
      };
    },
  },
  methods: {
    selectPredecessor(item) {
      this.predecessorLookup = false;
      if (item.nsn) {
        this.checkIdx = 1;
        this.number = item.nsn;
      } else if (item.oto) {
        this.checkIdx = 2;
        this.oto = item.oto;
        this.plug = item.plug;
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "company": "Company",
    "first name": "First name",
    "last name": "Last name",
    "no street name": "No street name",
    "address": "Address",
    "extension number": "Extension number",
    "OTO ID": "OTO ID",
    "building": "Building",
    "OTO ID": "OTO ID",
    "OTO": "OTO",
    "plug": "Plug",
    "binding ID": "Binding ID",
    "Swisscom predecessor DB": "Swisscom predecessor DB",
    "Phonenumber": "Tel. or NSN",
    "Address": "Address",
    "search": "search"
  }, 
  "de": {
    "company": "Firma",
    "first name": "Vorname",
    "last name": "Nachname",
    "no street name": "Keine Strassenbezeichnung",
    "address": "Adresse",
    "extension number": "Anschlussnummer",
    "OTO ID": "OTO ID",
    "building": "Gebäude",
    "OTO ID": "OTO ID",
    "OTO": "OTO",
    "plug": "Plug",
    "binding ID": "Binding ID",
    "Swisscom predecessor DB": "Swisscom Vorgänger DB",
    "Phonenumber": "Tel. oder NSN",
    "Address": "Adresse",
    "search": "suchen"
  }
}
</i18n>
