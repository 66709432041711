/**
 * re-format WSG quali (services/bbcs/quali) response
 * @param {data} response
 */

function convertQualiResults(results) {
    var serviceIdx = 0;

    return results.map(function (service) {

        serviceIdx += 1;
        var profileIdx = 0;
        var otoIdx = 0;
        var timeSlotIdx = 0;

        // get max/min streaming rate
        var profiles = service.serviceprofiles != null ? service.serviceprofiles : [];
        var maxDownStream = 0;
        var maxUpStream = 0;
        profiles.forEach(function (profile) {
            var down = Number(profile.bw_down);
            var up = Number(profile.bw_up);
            if (maxDownStream < down) maxDownStream = down;
            if (maxUpStream < up) maxUpStream = up;
        });

        var otos = [];
        if (service.sockets != null)
            otos = service.sockets.map(function (socket) {
                otoIdx += 1;
                return {
                    ...socket,
                    id: otoIdx - 1,
                    oto_id: socket.oto + (socket.plug ? "." + socket.plug : "")
                };
            });
        else if (service.oto)
            otos = [{
                id: 0,
                oto_id: service.oto + (service.plug ? "." + service.plug : "")
            }]

        return {
            ...service,
            id: serviceIdx - 1,
            name: service.bbtype || "BX",
            maxDownStream: maxDownStream,
            maxUpStream: maxUpStream,
            address: service.parsed_address,
            otos: otos,
            profiles: profiles.map(function (profile) {
                profileIdx += 1;
                return { ...profile, id: profileIdx - 1 };
            }),
            timeSlots: service.timeslots != null
                ? service.timeslots.map(function (slot) {
                    timeSlotIdx += 1;
                    return { ...slot, id: timeSlotIdx - 1 };
                })
                : []
        }
    });
}

/**
 * re-format WSG quali (services/bbcs/quali) response
 * @param {data} response (list of quali results) 
 * @param {provider} "bbcs", "bbcs_fiber", "bbcs_xgspon"
 * 
 */
export default function (data, provider) {
    return [
        {
            id: 0,
            provider: provider,
            services: convertQualiResults(data)
        }
    ];
}

export {
    convertQualiResults
};