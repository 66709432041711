<template>
  <validation-provider
    :vid="namePrefix + name"
    :name="label !== null ? label : $t('language')"
    :rules="rules !== null ? rules : ''"
    v-slot="{ errors, classes }"
  >
    <v-select
      v-model="language"
      :name="namePrefix + name"
      :items="languages"
      item-text="title"
      item-value="code"
      :label="label != null ? label : $t('language')"
      :hint="hint"
      :persistent-hint="persistentHint"
      :error-messages="errors"
      :loading="loading"
      :class="classes"
      menu-props="auto"
    >
      <template v-slot:item="{ item }">
        <div>
          <lang-flag :iso="item.code" v-bind:squared="false" />
          <span class="ml-4">{{ item.title }}</span>
        </div>
      </template>
      <template v-slot:prepend>
        <lang-flag
          v-if="language"
          :iso="language"
          v-bind:squared="false"
          class="mr-2"
        />
      </template>
    </v-select>
  </validation-provider>
</template>

<script>
export default {
  name: "LanguageSelect",
  props: {
    value: {
      required: true,
    },
    name: {
      type: String,
      default: "language",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    namePrefix: {
      type: String,
      default: "",
    },
    codes: {
      type: Array,
      // as default, we provide the 4 iWay languages only!
      default: () => ["en", "de", "fr", "it"],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      language: this.value,
    };
  },
  computed: {
    languages: function () {
      var that = this;
      return [
        { code: "en", title: this.$i18n.t("english"), flag: "us" },
        { code: "de", title: this.$i18n.t("german"), flag: "de" },
        { code: "fr", title: this.$i18n.t("french"), flag: "fr" },
        { code: "it", title: this.$i18n.t("italian"), flag: "it" },
        { code: "es", title: this.$i18n.t("spanish"), flag: "es" },
        { code: "pt", title: this.$i18n.t("portuguese"), flag: "pt" },
      ].filter(function (lang) {
        return (
          that.codes === null ||
          that.codes === undefined ||
          that.codes.length === 0 ||
          that.codes.includes(lang.code)
        );
      });
    },
  },
  watch: {
    value: function () {
      this.language = this.value;
    },
    language: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "language": "Language",
    "german": "German",
    "english": "English",
    "french": "French",
    "italian": "Italian",
    "spanish": "Spanish",
    "portuguese": "Portuguese"
  }, 
  "de": {
    "language": "Sprache",
    "german": "Deutsch",
    "english": "Englisch",
    "french": "Französisch",
    "italian": "Italienisch",
    "spanish": "Spanisch",
    "portuguese": "Portugiesisch"
  }
}
</i18n>
