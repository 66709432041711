var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","left":_vm.left,"bottom":_vm.bottom,"rounded":_vm.rounded},scopedSlots:_vm._u([(_vm.icon)?{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"loading":_vm.loading}},on),[_vm._v(" "+_vm._s(_vm.range ? "mdi-calendar-multiple" : "mdi-calendar")+" ")])]}}:{key:"activator",fn:function({ on }){return [_c('validation-provider',{attrs:{"vid":_vm.name,"name":_vm.label != null ? _vm.label : _vm.$t('date'),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid, dirty, classes }){return [_c('v-text-field',_vm._g({class:_vm.noForm ? null : classes,attrs:{"name":_vm.name,"value":_vm.date != null && _vm.date.length == 2
            ? _vm.formatDate(_vm.date[0]) + ' - ' + _vm.formatDate(_vm.date[1])
            : _vm.showTodayAsAsap && _vm.date != null && _vm.isToday(_vm.date)
            ? _vm.$t('asap')
            : _vm.date != null
            ? _vm.formatDate(_vm.date)
            : _vm.showTodayAsAsap
            ? _vm.$t('asap')
            : '',"label":_vm.label != null ? _vm.label : _vm.$t('date'),"hint":_vm.label != null ? _vm.hint : '',"error-messages":errors,"success":_vm.noForm ? null : dirty && valid,"disabled":_vm.disabled,"prepend-icon":_vm.hideIcon ? null : _vm.range ? 'mdi-calendar-multiple' : 'mdi-calendar',"clearable":_vm.clearable,"loading":_vm.loading,"readonly":""},on:{"click:clear":function($event){return _vm.$emit('input', null)}}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-date-picker',{ref:"picker",attrs:{"allowed-dates":_vm.dateAllowed,"show-current":_vm.showCurrent,"range":_vm.range,"min":_vm.min != null ? _vm.min : null,"max":_vm.max != null ? _vm.max : null,"first-day-of-week":_vm.firstDayOfWeek != null
        ? _vm.firstDayOfWeek
        : _vm.$store.state.locale == 'en'
        ? 0
        : 1,"locale":_vm.$store.state.locale,"locale-first-day-of-year":_vm.$store.state.locale == 'en' ? 0 : 4,"active-picker":_vm.activePicker2 || undefined,"no-title":"","scrollable":""},on:{"change":function($event){_vm.show = !_vm.range || (_vm.date != null && _vm.date.length == 2) ? false : true}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),(_vm.showCancelBtn)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.show = false}}},[_vm._v(" $t(\"cancel\") ")]):_vm._e(),(_vm.showOkBtn)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" $t(\"ok\") ")]):_vm._e(),(_vm.showTodayBtn)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.date = _vm.todayString();
        _vm.$refs.menu.save(_vm.date);}}},[_vm._v(" "+_vm._s(_vm.todayBtnText != null ? _vm.todayBtnText : _vm.$t("today"))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }