<template>
  <div>
    <v-row v-if="!additionalHide">
      <v-col cols="12">
        <validation-provider
          :vid="namePrefix + additionalName"
          :name="
            additionalLabel != null ? additionalLabel : $t('address suffix')
          "
          :rules="additionalRules != null ? additionalRules : ''"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="additionalValue"
            :name="namePrefix + additionalName"
            :label="
              additionalLabel != null ? additionalLabel : $t('address suffix')
            "
            :hint="additionalHint != null ? additionalHint : null"
            :disabled="disabled || additionalDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="
              additionalMaxLength != null ? additionalMaxLength : null
            "
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
    </v-row>
    <swiss-address-autocomplete
      v-if="countryValue && countryValue == 'CH'"
      v-model="mirror"
      :zip-name="zipName"
      :zip-label="zipLabel"
      :zip-hint="zipHint"
      :zip-rules="zipRules"
      :zip-max-length="zipMaxLength"
      :zip-disabled="zipDisabled"
      :zip-hide="zipHide"
      :zip-required="zipRequired"
      :city-name="cityName"
      :city-label="cityLabel"
      :city-hint="cityHint"
      :city-rules="cityRules"
      :city-max-length="cityMaxLength"
      :city-disabled="cityDisabled"
      :city-hide="cityHide"
      :city-required="cityRequired"
      :street-name="streetName"
      :street-label="streetLabel"
      :street-hint="streetHint"
      :street-rules="streetRules"
      :street-max-length="streetMaxLength"
      :street-disabled="streetDisabled"
      :street-hide="streetHide"
      :street-number-name="streetNumberName"
      :street-number-label="streetNumberLabel"
      :street-number-hint="streetNumberHint"
      :street-number-rules="streetNumberRules"
      :street-number-max-length="streetNumberMaxLength"
      :street-number-disabled="streetNumberDisabled"
      :street-number-hide="streetNumberHide"
      :prefix-name="namePrefix"
      :disabled="disabled"
    />
    <v-row
      v-if="
        (!countryValue || countryValue != 'CH') &&
        !(streetHide && streetNumberHide)
      "
    >
      <v-col cols="9">
        <validation-provider
          v-if="!streetHide"
          :vid="namePrefix + streetName"
          :name="streetLabel != null ? streetLabel : $t('street')"
          :rules="streetRules != null ? streetRules : ''"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="streetValue"
            :name="namePrefix + streetName"
            :label="streetLabel != null ? streetLabel : $t('street')"
            :hint="streetHint != null ? streetHint : null"
            :disabled="disabled || streetDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="streetMaxLength != null ? streetMaxLength : null"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
      <v-col cols="3">
        <validation-provider
          v-if="!streetNumberHide"
          :vid="namePrefix + streetNumberName"
          :name="streetNumberLabel != null ? streetNumberLabel : $t('number')"
          :rules="streetNumberRules != null ? streetNumberRules : ''"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="streetNumberValue"
            :name="namePrefix + streetNumberName"
            :label="
              streetNumberLabel != null ? streetNumberLabel : $t('number')
            "
            :hint="streetNumberHint != null ? streetNumberHint : null"
            :disabled="disabled || streetNumberDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="
              streetNumberMaxLength != null ? streetNumberMaxLength : null
            "
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row
      v-if="(!countryValue || countryValue != 'CH') && !(zipHide && cityHide)"
    >
      <v-col cols="3">
        <validation-provider
          v-if="!zipHide"
          :vid="namePrefix + zipName"
          :name="zipLabel != null ? zipLabel : $t('zip')"
          :rules="
            (zipRules != null ? zipRules + '|' : '') +
            (zipRequired ? 'required|' : '')
          "
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="zipValue"
            :name="namePrefix + zipName"
            :label="zipLabel != null ? zipLabel : $t('zip')"
            :hint="zipHint != null ? zipHint : null"
            :disabled="disabled || zipDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="zipMaxLength != null ? zipMaxLength : null"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
      <v-col cols="9">
        <validation-provider
          v-if="!cityHide"
          :vid="namePrefix + cityName"
          :name="cityLabel != null ? cityLabel : $t('city')"
          :rules="
            (cityRules != null ? cityRules + '|' : '') +
            (cityRequired ? 'required|' : '')
          "
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="cityValue"
            :name="namePrefix + cityName"
            :label="cityLabel != null ? cityLabel : $t('city')"
            :hint="cityHint != null ? cityHint : null"
            :disabled="disabled || cityDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="cityMaxLength != null ? cityMaxLength : null"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row v-if="!countryHide">
      <v-col cols="12">
        <country-select
          v-model="countryValue"
          :name="countryName"
          :label="countryLabel"
          :hint="countryHint"
          :rules="countryRules"
          :disabled="disabled || countryDisabled"
          :max-length="countryMaxLength"
          :prefix="namePrefix"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SwissAddressAutocomplete from "@/components/basics/SwissAddressAutocomplete";
import CountrySelect from "@/components/basics/CountrySelect";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "AddressPostal",
  components: {
    SwissAddressAutocomplete,
    CountrySelect,
  },
  props: {
    value: {},
    zipName: {
      type: String,
      default: "zip",
    },
    zipLabel: {
      type: String,
      default: null,
    },
    zipHint: {
      type: String,
      default: null,
    },
    zipRules: {
      type: String,
      default: null,
    },
    zipMaxLength: {
      type: [Number, String],
      default: 10,
    },
    zipDisabled: {
      type: Boolean,
      default: false,
    },
    zipHide: {
      type: Boolean,
      default: false,
    },
    zipRequired: {
      type: Boolean,
      default: true,
    },
    cityName: {
      type: String,
      default: "city",
    },
    cityLabel: {
      type: String,
      default: null,
    },
    cityHint: {
      type: String,
      default: null,
    },
    cityRules: {
      type: String,
      default: null,
    },
    cityMaxLength: {
      type: [Number, String],
      default: 100,
    },
    cityDisabled: {
      type: Boolean,
      default: false,
    },
    cityHide: {
      type: Boolean,
      default: false,
    },
    cityRequired: {
      type: Boolean,
      default: true,
    },
    streetName: {
      type: String,
      default: "street",
    },
    streetLabel: {
      type: String,
      default: null,
    },
    streetHint: {
      type: String,
      default: null,
    },
    streetRules: {
      type: String,
      default: null,
    },
    streetMaxLength: {
      type: [Number, String],
      default: 100,
    },
    streetDisabled: {
      type: Boolean,
      default: false,
    },
    streetHide: {
      type: Boolean,
      default: false,
    },
    streetNumberName: {
      type: String,
      default: "street_number",
    },
    streetNumberLabel: {
      type: String,
      default: null,
    },
    streetNumberHint: {
      type: String,
      default: null,
    },
    streetNumberRules: {
      type: String,
      default: null,
    },
    streetNumberMaxLength: {
      type: [Number, String],
      default: 10,
    },
    streetNumberDisabled: {
      type: Boolean,
      default: false,
    },
    streetNumberHide: {
      type: Boolean,
      default: false,
    },
    countryName: {
      type: String,
      default: "country",
    },
    countryLabel: {
      type: String,
      default: null,
    },
    countryHint: {
      type: String,
      default: null,
    },
    countryRules: {
      type: String,
      default: null,
    },
    countryMaxLength: {
      type: [Number, String],
      default: 2,
    },
    countryDisabled: {
      type: Boolean,
      default: false,
    },
    countryHide: {
      type: Boolean,
      default: false,
    },
    additionalName: {
      type: String,
      default: "additional",
    },
    additionalLabel: {
      type: String,
      default: null,
    },
    additionalHint: {
      type: String,
      default: null,
    },
    additionalRules: {
      type: String,
      default: null,
    },
    additionalMaxLength: {
      type: [Number, String],
      default: 255,
    },
    additionalDisabled: {
      type: Boolean,
      default: false,
    },
    additionalHide: {
      type: Boolean,
      default: false,
    },
    namePrefix: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [isMobile],
  data() {
    return {
      mirror: this.value ? { ...this.value } : {},
      additionalValue:
        this.value && this.value[this.additionalName] !== undefined
          ? this.value[this.additionalName]
          : null,
      zipValue:
        this.value && this.value[this.zipName] !== undefined
          ? this.value[this.zipName]
          : null,
      cityValue:
        this.value && this.value[this.cityName] !== undefined
          ? this.value[this.cityName]
          : null,
      streetValue:
        this.value && this.value[this.streetName] !== undefined
          ? this.value[this.streetName]
          : null,
      streetNumberValue:
        this.value && this.value[this.streetNumberName] !== undefined
          ? this.value[this.streetNumberName]
          : null,
      countryValue:
        this.value && this.value[this.countryName] !== undefined
          ? this.value[this.countryName]
          : null,
    };
  },
  watch: {
    additionalValue: function () {
      this.$emit("input", this.getValue());
    },
    zipValue: function () {
      this.$emit("input", this.getValue());
    },
    cityValue: function () {
      this.$emit("input", this.getValue());
    },
    streetValue: function () {
      this.$emit("input", this.getValue());
    },
    streetNumberValue: function () {
      this.$emit("input", this.getValue());
    },
    countryValue: function () {
      this.$emit("input", this.getValue());
    },
    value: function () {
      this.mirror = this.value ? { ...this.value } : {};
      this.additionalValue =
        this.value && this.value[this.additionalName] !== undefined
          ? this.value[this.additionalName]
          : null;
      this.zipValue =
        this.value && this.value[this.zipName] !== undefined
          ? this.value[this.zipName]
          : null;
      this.cityValue =
        this.value && this.value[this.cityName] !== undefined
          ? this.value[this.cityName]
          : null;
      this.streetValue =
        this.value && this.value[this.streetName] !== undefined
          ? this.value[this.streetName]
          : null;
      this.streetNumberValue =
        this.value && this.value[this.streetNumberName] !== undefined
          ? this.value[this.streetNumberName]
          : null;
      this.countryValue =
        this.value && this.value[this.countryName] !== undefined
          ? this.value[this.countryName]
          : null;
    },
  },
  created: function () {
    // add watcher for swiss-address-autocomple mirror
    var that = this;
    this.$watch("mirror." + this.zipName, function (value) {
      that.zipValue = value;
    });
    this.$watch("mirror." + this.cityName, function (value) {
      that.cityValue = value;
    });
    this.$watch("mirror." + this.streetName, function (value) {
      that.streetValue = value;
    });
    this.$watch("mirror." + this.streetNumberName, function (value) {
      that.streetNumberValue = value;
    });
  },
  methods: {
    getValue: function () {
      return Object.assign(this.value, {
        [this.additionalName]: this.additionalValue,
        [this.zipName]: this.zipValue,
        [this.cityName]: this.cityValue,
        [this.streetName]: this.streetValue,
        [this.streetNumberName]: this.streetNumberValue,
        [this.countryName]: this.countryValue,
      });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "zip": "Zip",
    "city": "City",
    "street": "Street",
    "number": "Street number",
    "address suffix": "Address suffix"
  }, 
  "de": {
    "zip": "PLZ",
    "city": "Ort",
    "street": "Strasse",
    "number": "Hausnummer",
    "address suffix": "Adresszusatz"
  }
}
</i18n>
