<template>
  <div>
    <v-row v-if="!(phoneHide && mobileHide && faxHide)">
      <v-col :cols="faxHide ? 6 : 4">
        <validation-provider
          v-if="!phoneHide"
          :vid="namePrefix + phoneName"
          :name="phoneLabel != null ? phoneLabel : $t('phone')"
          :rules="phoneRules ? phoneRules + '|phone' : 'phone'"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="phoneValue"
            :name="namePrefix + phoneName"
            :label="phoneLabel != null ? phoneLabel : $t('phone')"
            :hint="phoneHint != null ? phoneHint : null"
            :disabled="disabled || phoneDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="phoneMaxLength != null ? phoneMaxLength : null"
            @change="phoneValue = formatPhoneNumber(phoneValue)"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
      <v-col :cols="faxHide ? 6 : 4">
        <validation-provider
          v-if="!mobileHide"
          :vid="namePrefix + mobileName"
          :name="mobileLabel != null ? mobileLabel : $t('mobile')"
          :rules="mobileRules ? mobileRules + '|phone' : 'phone'"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="mobileValue"
            :name="namePrefix + mobileName"
            :label="mobileLabel != null ? mobileLabel : $t('mobile')"
            :hint="mobileHint != null ? mobileHint : null"
            :disabled="disabled || mobileDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="mobileMaxLength != null ? mobileMaxLength : null"
            @change="mobileValue = formatPhoneNumber(mobileValue)"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
      <v-col cols="4" v-if="!faxHide">
        <validation-provider
          :vid="namePrefix + faxName"
          :name="faxLabel != null ? faxLabel : $t('fax')"
          :rules="faxRules ? faxRules + '|phone' : 'phone'"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="faxValue"
            :name="namePrefix + faxName"
            :label="faxLabel != null ? faxLabel : $t('fax')"
            :hint="mobileHint != null ? mobileHint : null"
            :disabled="disabled || faxDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="faxMaxLength != null ? faxMaxLength : null"
            @change="faxValue = formatPhoneNumber(faxValue)"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row v-if="!emailHide">
      <v-col cols="12" v-if="emailMultiple">
        <email-list-input
          v-model="emailValue"
          :name="namePrefix + emailName"
          :label="emailLabel != null ? emailLabel : $t('email')"
          :hint="emailHint != null ? emailHint : null"
          :disabled="disabled || emailDisabled"
          :required="emailRequired ? true : false"
          :max-length="emailMaxLength ? emailMaxLength : null"
          multiple
        />
      </v-col>
      <v-col cols="12" v-else>
        <validation-provider
          :vid="namePrefix + emailName"
          :name="emailLabel != null ? emailLabel : $t('email')"
          :rules="(emailRules != null ? emailRules : '') + 'email'"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="emailValue"
            :name="namePrefix + emailName"
            :label="emailLabel != null ? emailLabel : $t('email')"
            :hint="emailHint != null ? emailHint : null"
            :disabled="disabled || emailDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="emailMaxLength != null ? emailMaxLength : null"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmailListInput from "@/components/basics/EmailListInput";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "AddressContacts",
  components: {
    EmailListInput,
  },
  mixins: [formatPhoneNumber, isMobile],
  props: {
    value: {},
    phoneName: {
      type: String,
      default: "phone",
    },
    phoneLabel: {
      type: String,
      default: null,
    },
    phoneHint: {
      type: String,
      default: null,
    },
    phoneRules: {
      type: String,
      default: null,
    },
    phoneMaxLength: {
      type: [Number, String],
      default: null,
    },
    phoneDisabled: {
      type: Boolean,
      default: false,
    },
    phoneHide: {
      type: Boolean,
      default: false,
    },
    mobileName: {
      type: String,
      default: "mobile",
    },
    mobileLabel: {
      type: String,
      default: null,
    },
    mobileHint: {
      type: String,
      default: null,
    },
    mobileRules: {
      type: String,
      default: null,
    },
    mobileMaxLength: {
      type: [Number, String],
      default: null,
    },
    mobileDisabled: {
      type: Boolean,
      default: false,
    },
    mobileHide: {
      type: Boolean,
      default: false,
    },
    faxName: {
      type: String,
      default: "fax",
    },
    faxLabel: {
      type: String,
      default: null,
    },
    faxHint: {
      type: String,
      default: null,
    },
    faxRules: {
      type: String,
      default: null,
    },
    faxMaxLength: {
      type: [Number, String],
      default: null,
    },
    faxDisabled: {
      type: Boolean,
      default: false,
    },
    faxHide: {
      type: Boolean,
      default: false,
    },
    emailName: {
      type: String,
      default: "email",
    },
    emailLabel: {
      type: String,
      default: null,
    },
    emailHint: {
      type: String,
      default: null,
    },
    emailRequired: {
      type: Boolean,
      default: false,
    },
    emailMultiple: {
      type: Boolean,
      default: false,
    },
    emailMaxLength: {
      type: [Number, String],
      default: null,
    },
    emailDisabled: {
      type: Boolean,
      default: false,
    },
    emailHide: {
      type: Boolean,
      default: false,
    },
    namePrefix: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneValue:
        this.value && this.value[this.phoneName] !== undefined
          ? this.value[this.phoneName]
          : null,
      mobileValue:
        this.value && this.value[this.mobileName] !== undefined
          ? this.value[this.mobileName]
          : null,
      faxValue:
        this.value && this.value[this.faxName] !== undefined
          ? this.value[this.faxName]
          : null,
      emailValue:
        this.value && this.value[this.emailName] !== undefined
          ? this.value[this.emailName]
          : null,
    };
  },
  watch: {
    phoneValue() {
      this.$emit("input", this.getValue());
    },
    mobileValue() {
      this.$emit("input", this.getValue());
    },
    faxValue() {
      this.$emit("input", this.getValue());
    },
    emailValue() {
      this.$emit("input", this.getValue());
    },
    value: function () {
      this.phoneValue =
        this.value && this.value[this.phoneName] !== undefined
          ? this.value[this.phoneName]
          : null;
      (this.mobileValue =
        this.value && this.value[this.mobileName] !== undefined
          ? this.value[this.mobileName]
          : null),
        (this.faxValue =
          this.value && this.value[this.faxName] !== undefined
            ? this.value[this.faxName]
            : null);
      this.emailValue =
        this.value && this.value[this.emailName] !== undefined
          ? this.value[this.emailName]
          : null;
    },
  },
  methods: {
    getValue: function () {
      return Object.assign(this.value, {
        [this.phoneName]: this.phoneValue,
        [this.mobileName]: this.mobileValue,
        [this.faxName]: this.faxValue,
        [this.emailName]: this.emailValue,
      });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "phone": "Phone",
    "mobile": "Mobile",
    "fax": "Fax",
    "email": "Email"
  }, 
  "de": {
    "phone": "Telefon",
    "mobile": "Mobil",
    "fax": "Fax",
    "email": "Email"
  }
}
</i18n>
