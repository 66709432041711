<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("order issue") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-title>{{
      profile != null && profile.gfo_variant != null
        ? profile.gfo_variant.title
        : ""
    }}</v-card-title>
    <v-card-subtitle>{{
      profile != null && profile.gfo_network != null
        ? profile.gfo_network.title
        : ""
    }}</v-card-subtitle>
    <v-card-text>
      <v-simple-table dense>
        <tbody>
          <tr>
            <td>{{ $t("street/number") }}</td>
            <td>
              {{ installAddress != null ? installAddress.street : "" }}
              {{
                installAddress != null
                  ? installAddress.house_number || installAddress.street_number
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("zip/city") }}</td>
            <td>
              {{ installAddress != null ? installAddress.zip : "" }}
              {{ installAddress != null ? installAddress.city : "" }}
            </td>
          </tr>
          <tr v-if="installAddress != null && installAddress.building">
            <td>{{ $t("building") }}</td>
            <td>{{ installAddress.building }}</td>
          </tr>
          <tr v-if="oto">
            <td>{{ $t("OTO ID") }}</td>
            <td>{{ oto }}</td>
          </tr>
          <tr v-if="plug">
            <td>{{ $t("plug") }}</td>
            <td>{{ plug }}</td>
          </tr>
          <tr v-if="service != null && service.up">
            <td>{{ $t("transfer point") }}</td>
            <td>{{ service.up }}</td>
          </tr>
          <tr v-if="wishDate != null && wishDate != ''">
            <td>{{ $t("wishdate") }}</td>
            <td>{{ formatDate(wishDate) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import { createNamespacedHelpers } from "vuex";

const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers("inetOrder");

export default {
  name: "OrderSummary",
  mixins: [formatDate],
  computed: {
    ...mapState(["oto", "plug", "wishDate"]),
    ...mapGetters(["profile", "service", "installAddress"]),
  },
};
</script>

<i18n>
{
  "en": {
    "order issue": "Order issue",
    "provider": "Provider",
    "profile": "Profile",
    "street/number": "Street/Number",
    "zip/city": "Zip/City",
    "building": "Building",
    "OTO ID": "OTO ID",
    "plug": "Plug",
    "transfer point": "Transfer point",
    "wishdate": "Wishdate"
  }, 
  "de": {
    "order issue": "Bestellung",
    "provider": "Anbieter",
    "profile": "Profil",
    "street/number": "Strasse/Nummer",
    "zip/city": "PLZ/Ort",
    "building": "Gebäude",
    "OTO ID": "OTO ID",
    "plug": "Dose",
    "transfer point": "Übergabepunkt",
    "wishdate": "Wunschdatum"
  }
}
</i18n>
