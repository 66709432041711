<template>
  <v-list-item-group>
    <v-list color="transparent" rounded>
      <v-list-item-group v-model="profileIdx">
        <v-list-item
          v-for="(profile, i) in profiles"
          :key="i"
          active-class="primary white--text"
        >
          <v-list-item-content>
            <v-list-item-title>{{
              profile.gfo_variant.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-list-item-group>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers("inetOrder");

export default {
  name: "MetronetProfiles",
  props: {
    resetProfileIdx: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(["profiles"]),
    profileIdx: {
      ...mapState({ get: "profileIdx" }),
      ...mapMutations({ set: "setProfileIdx" })
    }
  }
};
</script>
