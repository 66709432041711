/**
 * jacked from: https://github.com/sindresorhus/pretty-bytes
 */

export default {
  methods: {
    formatBandwidth: function (down, up, base = 1000) {
      if (typeof down !== 'number' || isNaN(down)) {
        throw new TypeError('Expected a number for "down"');
      }
      if (typeof up !== 'number' || isNaN(up)) {
        throw new TypeError('Expected a number for "up"');
      }

      var unit = null;
      var divisor = 1;

      if (base == 1000) {
        if (down > 1000000000000000000000) {
          unit = 'YBit/s';
          divisor = 1000000000000000000000;
        } else if (down > 1000000000000000000) {
          unit = 'ZBit/s';
          divisor = 1000000000000000000;
        } else if (down > 1000000000000000) {
          unit = 'EBit/s';
          divisor = 1000000000000000;
        } else if (down > 1000000000000) {
          unit = 'PBit/s';
          divisor = 1000000000000;
        } else if (down > 1000000000) {
          unit = 'TBit/s';
          divisor = 1000000000;
        } else if (down > 1000000) {
          unit = 'GBit/s';
          divisor = 1000000;
        } else if (down > 1000) {
          unit = 'MBit/s';
          divisor = 1000;
        } else {
          unit = 'KBit/s';
          divisor = 1;
        }
      } else if (base == 1) {
        if (down > 1000000000000000000000000) {
          unit = 'YBit/s';
          divisor = 1000000000000000000000000;
        } else if (down > 1000000000000000000000) {
          unit = 'ZBit/s';
          divisor = 1000000000000000000000;
        } else if (down > 1000000000000000000) {
          unit = 'EBit/s';
          divisor = 1000000000000000000;
        } else if (down > 1000000000000000) {
          unit = 'PBit/s';
          divisor = 1000000000000000;
        } else if (down > 1000000000000) {
          unit = 'TBit/s';
          divisor = 1000000000000;
        } else if (down > 1000000000) {
          unit = 'GBit/s';
          divisor = 1000000000;
        } else if (down > 1000000) {
          unit = 'MBit/s';
          divisor = 1000000;
        } else if (down > 1000) {
          unit = 'KBit/s';
          divisor = 1000;
        } else {
          unit = 'Bit/s';
          divisor = 1;
        }
      } else {
        throw new Error({ 'error': 'invalid base ' + base });
      }
      return (down / divisor) + '/' + (up / divisor) + ' ' + unit;
    }
  }
};
