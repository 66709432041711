<template>
  <v-card elevation="0">
    <v-data-table
      :headers="headers"
      :items="results"
      :loading="loading"
      :mobile-breakpoint="mbreakpoint"
      sort-by="last_modified"
      sort-desc
      :footer-props="{
        itemsPerPageOptions: [15, 50, -1],
      }"
      :hide-default-footer="results.length <= 15"
    >
      <template v-slot:item.nsn="{ item }">
        <template v-if="item.nsn">
          <v-chip color="secondary" @click="$emit('select', item)">
            {{ item.nsn }}
          </v-chip>
        </template>
      </template>
      <template v-slot:item.oto="{ item }">
        <template v-if="item.oto">
          <v-chip color="secondary" @click="$emit('select', item)">
            {{ item.oto + (item.plug ? "." + item.plug : "") }}
          </v-chip>
        </template>
      </template>
      <template v-slot:item.from_date="{ item }">
        <div class="monospace">
          {{ item.from_date ? formatDate(item.from_date) : "-" }}
        </div>
      </template>
      <template v-slot:item.to_date="{ item }">
        <div class="monospace">
          {{ item.to_date ? formatDate(item.to_date) : "-" }}
        </div>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('close')" :disabled="loading" text>
        {{ $t("back") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "PredecessorLookup",
  mixins: [formatDate, isMobile],
  props: {
    address: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    results: [],
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("NSN"),
          value: "nsn",
        },
        {
          text: this.$i18n.t("OTO"),
          value: "oto",
        },
        {
          text: this.$i18n.t("company"),
          value: "name.company",
        },
        {
          text: this.$i18n.t("first name"),
          value: "name.first_name",
        },
        {
          text: this.$i18n.t("last name"),
          value: "name.last_name",
        },
        {
          text: this.$i18n.t("from date"),
          value: "from_date",
        },
        {
          text: this.$i18n.t("to date"),
          value: "to_date",
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getPredecessors() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/bbcs/predecessors", {
          params: {
            phone_nr: this.address.phone_nr,
            street: this.address.street,
            house_nr: this.address.street_number,
            zip: this.address.zip,
            city: this.address.city,
            building: this.address.building,
            first_name: this.address.first_name,
            last_name: this.address.last_name,
            company: this.company,
          },
        })
        .then((response) => {
          that.results = response.data;
        })
        .catch((error) => {
          if (error.invalid()) {
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getPredecessors();
  },
};
</script>

<i18n>
{
  "en": {
   "Swisscom predecessor DB results": "Swisscom predecessor DB results",
   "NSN": "NSN",
   "OTO": "OTO",
   "plug": "Plug",
   "company": "Company",
   "first name": "First name",
   "last name": "Last name",
   "address": "Address",
   "back": "Back",
   "from date": "From date",
   "to date": "To date"
  },
  "de": {
   "Swisscom predecessor DB results": "Swisscom Vorgänger DB Ergebnisse",
   "NSN": "NSN",
   "OTO": "OTO",
   "plug": "Plug",
   "company": "Firma",
   "first name": "Vorname",
   "last name": "Nachname",
   "address": "Adresse",
   "back": "Zurück",
   "from date": "Datum ab",
   "to date": "Datum bis"
  }
}
</i18n>
