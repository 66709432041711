<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>
          {{ $t("additional product features") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row justify="center" align="end" v-if="isBbcs">
          <v-col cols="6">
            <v-select
              v-model="sessionType"
              :items="sessionTypes"
              :label="$t('session type')"
              :disabled="!allowSessionTypePpp"
            >
              <template v-slot:selection="{ item }">
                <v-chip color="primary">{{ $t(item) }}</v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip color="primary">{{ $t(item) }}</v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="pool"
              :items="pools"
              :label="$t('IP pool')"
              :disabled="sessionType != 'DHCP'"
            >
              <template v-slot:selection="{ item }">
                <v-chip :color="sessionType == 'DHCP' ? 'primary' : ''">
                  {{ $t(item) }}
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip :color="sessionType == 'DHCP' ? 'primary' : ''">
                  {{ $t(item) }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row justify="center" align="end" v-if="isBbcs">
          <v-col cols="4">
            <validation-provider
              vid="radius_username"
              :name="$t('username')"
              rules="min:1|max:255"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="radiusUsername"
                :label="$t('username')"
                :hint="$t('optional RADIUS username')"
                :error-messages="errors"
                :success="dirty && valid"
                :disabled="
                  sessionType == 'DHCP' ||
                  (radiusSuffix != null
                    ? radiusSuffix.select_username == false
                    : false)
                "
                :class="classes"
                maxlength="255"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="radius_suffix"
              :name="$t('suffix')"
              rules="min:1|max:255"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="radiusSuffixIdx"
                :items="fixedRadiusSuffixes"
                item-text="suffix"
                item-value="id"
                :label="$t('suffix')"
                :hint="$t('optional RADIUS suffix')"
                :disabled="sessionType == 'DHCP'"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                chips
              >
                <template v-slot:selection="{ item }">
                  <v-chip :color="item.default ? 'primary' : 'secondary'">
                    {{ item.suffix }}
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip :color="item.default ? 'primary' : 'secondary'">
                    {{ item.suffix }}
                  </v-chip>
                </template>
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="radius_password"
              :name="$t('password')"
              rules="min:1|max:255"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="radiusPassword"
                :label="$t('password')"
                :hint="$t('optional RADIUS password')"
                :disabled="sessionType == 'DHCP'"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                maxlength="255"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center" align="center" v-if="fixIpOptions.length > 0">
          <v-col cols="2" class="mt-2 font-weight-bold">
            {{ $t("fix IP addresses") }}:
          </v-col>
          <v-col cols="10">
            <v-chip-group
              v-model="fixIpOpt"
              color="primary"
              :mobile-breakpoint="mbreakpoint"
              multiple
              column
            >
              <v-chip
                v-for="(opt, i) in fixIpOptions"
                :key="i"
                :disabled="upgrade || opt.disabled"
                @input="updateGfoOption(opt, $event)"
                outlined
                label
                filter
              >
                {{ opt.title }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          align="center"
          v-if="!upgrade && ripeNetnameRequired"
        >
          <v-col cols="12">
            <validation-provider
              vid="customer.ripeNetname"
              :name="$t('RIPE network name')"
              rules="required|max:50|alpha_dash"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="ripeNetname"
                name="customer.ripeNetname"
                :label="$t('RIPE network name')"
                :hint="$t('network name for RIPE inetnum object')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                maxlength="50"
                :clearable="!isMobile"
                counter
                persistent-hint
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          align="center"
          v-if="streamingOptions.length > 0"
        >
          <v-col cols="2" class="mt-2 font-weight-bold">
            {{ $t("TV streaming") }}:
          </v-col>
          <v-col cols="10">
            <v-chip-group
              v-model="streamingOpt"
              color="primary"
              :mobile-breakpoint="mbreakpoint"
              column
            >
              <v-chip
                v-for="(opt, i) in streamingOptions"
                :key="i"
                :disabled="opt.disabled"
                @input="updateGfoOption(opt, $event)"
                outlined
                label
                filter
              >
                {{ opt.title }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <v-row justify="center" align="center" v-if="slaOptions.length > 0">
          <v-col cols="2" class="mt-2 font-weight-bold">
            {{ $t("SLAs") }}:
          </v-col>
          <v-col cols="10">
            <v-chip-group
              v-model="slaOpt"
              color="primary"
              :mobile-breakpoint="mbreakpoint"
              column
            >
              <v-chip
                v-for="(opt, i) in slaOptions"
                :key="i"
                :disabled="opt.disabled"
                @input="updateGfoOption(opt, $event)"
                outlined
                label
                filter
              >
                {{ opt.title }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <v-row v-show="ipMonitoring">
          <v-col cols="12">
            <v-card>
              <v-toolbar color="titleBar" dark flat dense>
                <v-toolbar-title>
                  {{ $t("SLA notifications") }}
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      vid="sla_email"
                      :name="$t('email')"
                      v-slot="{ errors, valid, dirty, classes }"
                    >
                      <email-list-input
                        v-model="slaEmail"
                        name="sla_email"
                        :label="$t('email')"
                        :hint="$t('notify email address')"
                        :error-messages="errors"
                        :success="dirty && valid"
                        :class="classes"
                        max-length="1024"
                        multiple
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      vid="sla_mobile"
                      :name="$t('mobile')"
                      v-slot="{ errors, valid, dirty, classes }"
                    >
                      <v-text-field
                        v-model="slaMobile"
                        name="sla_mobile"
                        :label="$t('mobile')"
                        :hint="$t('notify mobile number')"
                        :error-messages="errors"
                        :success="dirty && valid"
                        :class="classes"
                        maxlength="17"
                        :clearable="!isMobile"
                        counter
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center" align="center" v-if="isXgsPon">
          <v-col cols="6">
            <validation-provider
              vid="router_type"
              :name="$t('router type')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="routerType"
                :items="routerTypes"
                itemText="name"
                itemValue="name"
                :label="$t('router type')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              vid="xgs_serial_number"
              :name="$t('XGS serial number')"
              :rules="(routerType ? 'required|' : '') + 'xgssn'"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="xgsSerialNumber"
                name="xgs_serial_number"
                :label="$t('XGS serial number')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                counter="12"
                maxlength="12"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>
          {{ $t("ordering information") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row justify="center" align="end" v-if="!isBbcs">
          <v-col cols="12" class="pr-12">
            <date-picker
              v-model="wishDate"
              :label="$t('wishdate')"
              :hint="$t('service activation wishdate')"
              :allowedDates="allowedWishDates"
              :rules="
                timeSlot != null
                  ? 'min_date:' + timeSlot.date + ',' + wishDate
                  : ''
              "
              today
            />
          </v-col>
        </v-row>
        <v-row justify="center" align="end">
          <v-col cols="12" class="pr-12">
            <validation-provider vid="email" :name="$t('email')">
              <email-list-input
                v-model="orderEmail"
                :label="$t('email')"
                :hint="$t('email for order confirmation')"
                max-length="1024"
                multiple
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row justify="center" align="end">
          <v-col cols="12">
            <validation-provider
              vid="product_description"
              :name="$t('product description')"
              rules="max:100"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="productDescr"
                name="product_description"
                :label="$t('product description')"
                :hint="$t('product description appears on invoice')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                counter="100"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row justify="center" align="end">
          <v-col cols="12">
            <validation-provider
              vid="note"
              :name="$t('order note')"
              rules="min:1|max:1024"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-textarea
                v-model="orderNote"
                name="note"
                :label="$t('order note')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                maxlength="1024"
                rows="1"
                auto-grow
                :clearable="!isMobile"
                counter
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="service == null && isBbcs">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>
          {{ $t("consumer data") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <address-names
          v-model="predecessor"
          first-name-name="predecessor_first_name"
          first-name-max-length="30"
          :first-name-hint="
            $t('first name for submission to the predecessor database')
          "
          first-name-rules="required"
          last-name-max-length="30"
          :last-name-hint="
            $t('last name for submission to the predecessor database')
          "
          last-name-name="predecessor_last_name"
          last-name-rules="required"
          company-hide
          salutation-hide
        />
      </v-card-text>
    </v-card>

    <v-card v-if="service == null && contactNeeded">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>
          {{ isBbcs ? $t("onsite installation contact") : $t("consumer data") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <address-names
          v-model="contact"
          company-max-length="30"
          first-name-max-length="30"
          first-name-rules="required"
          last-name-max-length="30"
          last-name-rules="required"
          :name-prefix="'contact.'"
          salutation-hide
        />
      </v-card-text>

      <v-card-text>
        <address-contacts
          v-model="contact"
          :phone-rules="contact.mobile ? '' : 'required'"
          :mobile-rules="contact.phone ? '' : 'required'"
          :name-prefix="'contact.'"
          email-max-length="1024"
          :email-hint="$t('contact email address')"
          email-multiple
          fax-hide
        />
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <language-select
              v-model="contact.language"
              name="contact.language"
              :label="$t('language of contact')"
            />
          </v-col>
        </v-row>
        <v-row v-if="isBbcs">
          <v-col cols="12">
            <validation-provider
              vid="contact.comment"
              :name="$t('comment')"
              rules="max:500"
              v-slot="{ valid, dirty, errors, classes }"
            >
              <v-textarea
                name="contact.comment"
                v-model="contact.comment"
                :hint="$t('contact comment')"
                rows="1"
                :label="$t('comment')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                maxlength="500"
                counter
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import EmailListInput from "@/components/basics/EmailListInput";
import AddressNames from "@/components/basics/AddressNames";
import AddressPostal from "@/components/basics/AddressPostal";
import AddressContacts from "@/components/basics/AddressContacts";
import LanguageSelect from "@/components/basics/LanguageSelect";
import DatePicker from "@/components/basics/DatePicker";
import isMobile from "@/utils/mixins/isMobile";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("inetOrder");

export default {
  name: "CreateOrder",
  components: {
    EmailListInput,
    AddressNames,
    AddressContacts,
    AddressPostal,
    LanguageSelect,
    DatePicker,
  },
  mixins: [isMobile],
  props: {
    service: {
      type: Object,
      required: false,
      default: null,
    },
    upgrade: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    fixIpOpt: [],
    slaOpt: null,
    streamingOpt: null,
    sessionTypes: ["DHCP", "PPP"],
    pools: ["pool1", "pool2"],
    contact: {
      company: "",
      salutation: "n",
      first_name: "",
      last_name: "",
      phone: "",
      mobile: "",
      fax: "",
      email: "",
      street: "",
      house_number: "",
      zip: "",
      city: "",
      additional: "",
      country: "CH",
      language: "de",
      comment: "",
    },
    predecessor: {
      predecessor_company: "",
      predecessor_first_name: "",
      predecessor_last_name: "",
    },
    routerTypes: [],
  }),
  computed: {
    ...mapState([
      "installAddress",
      "number",
      "oto",
      "plug",
      "bindingId",
      "isBbcs",
      "bbType",
      "streaming",
      "options",
    ]),
    ...mapGetters([
      "result",
      "profile",
      "timeSlot",
      "contactNeeded",
      "allowSessionTypePpp",
    ]),
    ...mapGetters({
      radiusSuffixes: "bbcsRadiusSuffixes",
      radiusSuffix: "bbcsRadiusSuffix",
    }),
    wishDate: {
      ...mapState({ get: "wishDate" }),
      ...mapMutations({ set: "setWishDate" }),
    },
    streaming: {
      ...mapState({ get: "streaming" }),
      ...mapMutations({ set: "setStreaming" }),
    },
    sessionType: {
      ...mapState({ get: "sessionType" }),
      ...mapMutations({ set: "setSessionType" }),
    },
    pool: {
      ...mapState({ get: "pool" }),
      ...mapMutations({ set: "setPool" }),
    },
    radiusSuffixIdx: {
      ...mapState({ get: "bbcsRadiusSuffixIdx" }),
      ...mapMutations({ set: "setBbcsRadiusSuffixIdx" }),
    },
    radiusUsername: {
      ...mapState({ get: "bbcsRadiusUsername" }),
      ...mapMutations({ set: "setBbcsRadiusUsername" }),
    },
    radiusPassword: {
      ...mapState({ get: "bbcsRadiusPassword" }),
      ...mapMutations({ set: "setBbcsRadiusPassword" }),
    },
    productDescr: {
      ...mapState({ get: "productDescr" }),
      ...mapMutations({ set: "setProductDescr" }),
    },
    orderEmail: {
      ...mapState({ get: "orderEmail" }),
      ...mapMutations({ set: "setOrderEmail" }),
    },
    orderNote: {
      ...mapState({ get: "orderNote" }),
      ...mapMutations({ set: "setOrderNote" }),
    },
    slaEmail: {
      ...mapState({ get: "slaEmail" }),
      ...mapMutations({ set: "setSlaEmail" }),
    },
    slaMobile: {
      ...mapState({ get: "slaMobile" }),
      ...mapMutations({ set: "setSlaMobile" }),
    },
    ripeNetname: {
      ...mapState({ get: "ripeNetname" }),
      ...mapMutations({ set: "setRipeNetname" }),
    },
    routerType: {
      ...mapState({ get: "routerType" }),
      ...mapMutations({ set: "setRouterType" }),
    },
    xgsSerialNumber: {
      ...mapState({ get: "xgsSerialNumber" }),
      ...mapMutations({ set: "setXgsSerialNumber" }),
    },
    gfoOptions() {
      // force recompute
      this.profile;

      var that = this;
      var opts = [];
      if (this.profile != null && this.profile.gfo_options != null)
        opts = this.profile.gfo_options.map(function (opt) {
          return { ...opt, quantity: that.getGfoOptionQuantity(opt) };
        });

      return opts;
    },
    fixIpOptions() {
      var i = 0;
      return this.gfoOptions
        .filter(function (opt) {
          return opt.product_type == "fixip";
        })
        .sort(function (a, b) {
          try {
            var aa = Number(a.title.split(" ")[0]);
            var bb = Number(b.title.split(" ")[0]);
            return aa - bb;
          } catch (error) {
            return a.id - b.id;
          }
        })
        .map(function (opt) {
          opt.index = i;
          i += 1;
          return opt;
        });
    },
    slaOptions() {
      var i = 0;
      return this.gfoOptions
        .filter(function (opt) {
          return opt.product_type == "sla";
        })
        .map(function (opt) {
          opt.index = i;
          i += 1;
          return opt;
        });
    },
    streamingOptions() {
      var i = 0;
      return this.gfoOptions
        .filter(function (opt) {
          return opt.product_type == "tv_streaming";
        })
        .map(function (opt) {
          opt.index = i;
          i += 1;
          return opt;
        });
    },
    fixedRadiusSuffixes() {
      /* provide available RADIUS suffixes or "DHCP" dummy list */
      return this.sessionType == "PPP"
        ? this.radiusSuffixes
        : [
            {
              id: this.radiusSuffixIdx,
              suffix: "dhcp",
              default: true,
              select_username: false,
              allow_fix_ip: false,
            },
          ];
    },
    ipMonitoring() {
      return (
        this.fixIpOpt.length > 0 &&
        this.slaOpt != null &&
        this.slaOpt in this.slaOptions &&
        this.slaOptions[this.slaOpt].tags.includes("ip-monitoring")
      );
    },
    ripeNetnameRequired() {
      var that = this;
      return (
        this.fixIpOpt.filter(function (idx) {
          return that.fixIpOptions[idx].tags.includes("do_ripe_submit");
        }).length > 0
      );
    },
    isXgsPon() {
      return (
        this.result &&
        this.result.provider &&
        !this.result.provider.includes("bbcs") &&
        this.profile &&
        this.profile.gfo_variant &&
        this.profile.gfo_variant.tags != null &&
        this.profile.gfo_variant.tags.includes("technology_xgs_pon")
      );
    },
  },
  watch: {
    radiusSuffix() {
      if (this.radiusSuffix != null && !this.radiusSuffix.select_username)
        this.radiusUsername = "";
      else if (
        this.radiusSuffix != null &&
        this.radiusSuffix.select_username &&
        !this.radiusUsername
      )
        this.radiusUsername = this.service
          ? this.service.radius_username || ""
          : "";
      this.setOptions(this.gfoOptions);
      this.validateGfoOptions();
    },
    contact: {
      immediate: true,
      deep: true,
      handler(value) {
        this.setOrderContact(this.contact);
      },
    },
    orderEmail: {
      immediate: true,
      handler(value) {
        if (this.orderEmail == null || this.orderEmail == "")
          this.orderEmail = this.$store.getters.deliveryEmail;
      },
    },
    predecessor: {
      immediate: true,
      deep: true,
      handler(value) {
        this.setPredecessorInfo(this.predecessor);
      },
    },
    sessionType() {
      this.validateGfoOptions();
    },
    bbType() {
      this.validateGfoOptions();
    },
    gfoOptions: {
      immediate: true,
      handler(opts) {
        this.setOptions([]); // reset store options
        this.validateGfoOptions(); // initialize
      },
    },
    allowSessionTypePpp: {
      immediate: true,
      handler(value) {
        if (value == false && this.sessionType == "PPP")
          this.sessionType = "DHCP";
      },
    },
  },
  methods: {
    ...mapMutations([
      "setOptions",
      "updateOption",
      "setOrderContact",
      "setPredecessorInfo",
    ]),
    getGfoOptionQuantity(opt) {
      /* set quantity base on current service */
      if (this.service != null && this.service.gfo_options != null) {
        var opts = this.service.gfo_options.filter(function (o) {
          return o.id == opt.id;
        });
        if (opts.length > 0) {
          return opts[0].quantity;
        }
      }
      return 0;
    },
    validateGfoOptions() {
      var that = this;
      this.gfoOptions.forEach(function (opt) {
        if (opt.product_type == "fixip") {
          /* option is disabled when:
            - radius suffix does not allow fix IPs
            - BBCS order with session type PPP
            - options requires SLA which is not selected
            */
          opt.disabled =
            (that.radiusSuffix != null
              ? that.radiusSuffix.allow_fix_ip == false
              : false) ||
            (that.isBbcs &&
              that.sessionType != "PPP" &&
              opt.tags.includes("sessiontype_ppp_only")) ||
            (opt.required_products.filter((o) => o.type == "sla").length > 0 &&
              that.slaOpt == null);
        } else if (opt.product_type == "sla") {
          opt.disabled = false;
        } else if (opt.product_type == "tv_streaming") {
          opt.disabled =
            (that.sessionType != "DHCP" &&
              opt.tags.includes("sessiontype_dhcp_only")) ||
            (that.bbType !== "VDSL" && opt.tags.includes("bbtype_vdsl_only"));
        }

        if (opt.disabled) {
          opt.quantity = 0;
          that.updateOption(opt);
        }
      });

      // set/init local data, store, ...
      this.fixIpOpt = this.fixIpOptions
        .filter((o) => o.quantity > 0)
        .map((o) => o.index);
      var streamingOpts = this.streamingOptions
        .filter((o) => o.quantity > 0)
        .map((o) => o.index);
      if (streamingOpts.length > 0) {
        this.streaming = true;
        if (this.streamingOpt != streamingOpts[0])
          this.streamingOpt = streamingOpts[0];
      }
      var slaOpts = this.slaOptions
        .filter((o) => o.quantity > 0)
        .map((o) => o.index);
      if (slaOpts.length > 0 && this.slaOpt != slaOpts[0])
        that.slaOpt = slaOpts[0];
    },
    updateGfoOption(opt, status) {
      if (!opt.disabled) {
        opt.quantity = status ? 1 : 0;
        this.updateOption(opt);
        this.validateGfoOptions();
      }
    },
    allowedWishDates(date) {
      if (this.timeSlot != null) {
        return (
          this.$moment(this.timeSlot.date).isSame(date, "day") ||
          this.$moment(this.timeSlot.date).isBefore(date)
        );
      }
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      return (
        this.$moment(today).isSame(date, "day") ||
        this.$moment(today).isBefore(date)
      );
    },
    getRouterTypes() {
      var that = this;
      this.$http
        .get("products/router-types")
        .then((response) => {
          this.routerTypes = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        });
    },
  },
  mounted() {
    this.getRouterTypes();
  },
};
</script>

<i18n>
{
  "en": {
    "fix IP addresses": "Fix IP addresses",
    "SLAs": "SLAs",
    "additional product features": "Additional product features",
    "install wishdate": "Install wishdate",
    "product description": "Product description",
    "product description appears on invoice": "product description appears on invoice",
    "email for order confirmation": "email for order confirmation",
    "ordering information": "ordering information",
    "consumer data": "Consumer data",
    "IP network name": "IP network name",
    "network name for RIPE inetnum object": "network name for RIPE inetnum object (e.g. company name)",
    "email": "Email",
    "order note": "Own note for order confirmation",
    "comment": "Comment",
    "wishdate": "Wishdate",
    "TV streaming": "TV streaming",
    "pool1": "Public",
    "pool2": "CGNAT (Carrier Grade NAT)",
    "session type": "Session type",
    "IP pool": "IP pool",
    "DHCP": "DHCP",
    "PPP": "PPP",
    "username": "Username",
    "optional RADIUS username": "optional RADIUS username",
    "password": "Password",
    "optional RADIUS password": "optional RADIUS password",
    "suffix": "Suffix",
    "optional RADIUS suffix": "optional RADIUS suffix",
    "service activation wishdate": "service activation wishdate",
    "SLA notifications": "SLA notifications",
    "mobile": "Mobile",
    "notify email address": "notify email address",
    "notify mobile number": "notify mobile number",
    "company name for submission to the predecessor database": "company name for submission to the predecessor database",
    "first name for submission to the predecessor database": "first name for submission to the predecessor database",
    "last name for submission to the predecessor database": "last name for submission to the predecessor database",
    "onsite installation contact": "onsite installation contact",
    "contact comment": "comment for this contact",
    "language of contact": "language of contact",
    "contact email address": "email address of contact",
    "XGS serial number": "XGS router serial number",
    "router type": "router type"
  }, 
  "de": {
    "fix IP addresses": "fixe IP Adressen",
    "SLAs": "SLAs",
    "additional product features": "zusätzliche Produkteigenschaften",
    "install wishdate": "Wunschdatum Aufschaltung",
    "product description": "Produktbeschreibung",
    "product description appears on invoice": "Produktbeschreibung erscheint auf der Rechnung",
    "email for order confirmation": "Email für Bestellbestätigung",
    "ordering information": "Angaben zur Bestellung",
    "consumer data": "Endkundenangaben",
    "IP network name": "IP Netzwerk Name",
    "network name for RIPE inetnum object": "Netzwerk Name für RIPE inetnum Objekt (z.B. Firmenname)",
    "email": "Email",
    "order note": "Eigene Notiz für Bestellbestätigung",
    "comment": "Kommentar",
    "wishdate": "Wunschdatum",
    "TV streaming": "TV Streaming",
    "pool1": "Public",
    "pool2": "CGNAT (Carrier Grade NAT)",
    "session type": "Sitzungstyp",
    "IP pool": "IP Pool",
    "DHCP": "DHCP",
    "PPP": "PPP",
    "username": "Benutzername",
    "optional RADIUS username": "optionaler RADIUS Benutzername",
    "password": "Passwort",
    "optional RADIUS password": "optionales RADIUS Passwort",
    "suffix": "Suffix",
    "optional RADIUS suffix": "optionales RADIUS Suffix",
    "service activation wishdate": "Dienst Aktivierungswunschdatum",
    "SLA notifications": "SLA Benachrichtigungen",
    "mobile": "Mobile",
    "notify email address": "Benachrichtigungs Email Adresse",
    "notify mobile number": "Benachrichtigungs Mobilnummer",
    "company name for submission to the predecessor database": "Firmenname zür Übertragung an die Vorgänger Datenbank",
    "first name for submission to the predecessor database": "Vorname zür Übertragung an die Vorgänger Datenbank",
    "last name for submission to the predecessor database": "Nachname zür Übertragung an die Vorgänger Datenbank",
    "onsite installation contact": "Vorort Installations Kontakt",
    "contact comment": "Kommentar für diesen Kontakt",
    "language of contact": "Sprache des Kontakts",
    "contact email address": "Email Adresse des Kontakts",
    "XGS serial number": "XGS Router Seriennummer",
    "router type": "Routertyp"
  }
}
</i18n>
