<template>
  <div>
    <v-progress-linear :active="loading" indeterminate />
    <v-alert
      v-if="!loading && qualiResults.length == 0"
      outlined
      type="info"
      prominent
      border="left"
    >
      {{ $t("no results found") }}
    </v-alert>
    <v-expansion-panels v-else v-model="qualiResultIdx">
      <template v-for="(result, i) in qualiResults">
        <v-expansion-panel :key="i">
          <v-expansion-panel-header class="title">
            {{ result.label }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card elevation="0" class="mb-4">
              <v-card-text>
                <v-row align="start" justify="center">
                  <v-col cols="6">
                    <div
                      v-if="!loading && result.result_detail"
                      :class="bbcsResultStateOk ? '' : 'red--text'"
                    >
                      {{ result.result_detail }}
                      <v-divider class="mb-4" />
                    </div>
                    <div
                      v-if="!loading && bbcsResultStateOkInfo"
                      class="red--text"
                    >
                      {{ $t("bbcsResultStateOkInfo") }}
                      <v-divider class="mb-4" />
                    </div>
                    <table width="100%" v-if="!loading">
                      <tr v-if="result.bbtype">
                        <td>{{ $t("broadband type") }}</td>
                        <td>{{ result.bbtype }}</td>
                      </tr>
                      <tr v-if="result.dn">
                        <td>{{ $t("NSN") }}</td>
                        <td>{{ result.dn }}</td>
                      </tr>
                      <tr v-if="result.address">
                        <td>{{ $t("address") }}</td>
                        <td>
                          <div>
                            {{ result.address.street }}
                            {{ result.address.street_number }},
                            {{
                              result.address.building
                                ? result.address.building + ", "
                                : ""
                            }}
                            {{ result.address.zip }}
                            {{ result.address.city }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="result.up">
                        <td>{{ $t("transfer point") }}</td>
                        <td>{{ result.up }}</td>
                      </tr>
                      <tr v-if="result.jumper">
                        <td>{{ $t("switch relevant") }}</td>
                        <td>
                          {{ result.jumper ? $t("yes") : $t("no") }}
                        </td>
                      </tr>
                      <tr v-if="result.bep_state">
                        <td>{{ $t("BEP state") }}</td>
                        <td>
                          {{ result.bep_state ? $t("yes") : $t("no") }}
                        </td>
                      </tr>
                      <tr v-if="result.contact_needed">
                        <td>{{ $t("contact needed") }}</td>
                        <td>
                          {{ result.contact_needed ? $t("yes") : $t("no") }}
                        </td>
                      </tr>
                      <tr v-if="result.flat_memo">
                        <td>{{ $t("flat memo") }}</td>
                        <td>
                          {{ result.flat_memo }}
                        </td>
                      </tr>
                      <tr v-if="result.oto">
                        <td>{{ $t("OTO") }}</td>
                        <td>{{ result.oto }} ({{ result.oto_state }})</td>
                      </tr>
                      <tr v-if="result.plug">
                        <td>{{ $t("plug") }}</td>
                        <td>{{ result.plug }}</td>
                      </tr>
                      <tr v-if="wishDate && !result.wishdate_ok">
                        <td>{{ $t("wishdate") }}</td>
                        <td>{{ $t("not available") }}</td>
                      </tr>
                      <tr v-if="wishDate && result.wishdate_ok">
                        <td>{{ $t("wishdate") }}</td>
                        <td>{{ $t("available") }}</td>
                      </tr>
                      <tr v-if="result.development != null" valign="top">
                        <td>{{ $t("site development") }}</td>
                        <td>
                          <v-row align="start" justify="center" no-gutters>
                            <v-col cols="6">
                              {{ $t("fully developed") }}:
                            </v-col>
                            <v-col cols="2">
                              {{
                                result.development.fully_developed
                                  ? $t("yes")
                                  : $t("no")
                              }}
                            </v-col>
                            <v-col cols="2">
                              {{ getSiteDevelopedPercent(result) }}
                            </v-col>
                            <v-col cols="2">
                              <v-btn
                                icon
                                @click="
                                  showSiteDevelopment = !showSiteDevelopment
                                "
                              >
                                <v-icon>mdi-chevron-down</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="showSiteDevelopment"
                            align="start"
                            justify="center"
                            no-gutters
                          >
                            <v-col cols="6">
                              {{ $t("utilization units") }}:
                            </v-col>
                            <v-col cols="6">
                              {{
                                result.development.utilization_units != null
                                  ? result.development.utilization_units
                                  : "-"
                              }}
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="showSiteDevelopment"
                            align="start"
                            justify="center"
                            no-gutters
                          >
                            <v-col cols="6">{{ $t("connections") }}:</v-col>
                            <v-col cols="6">
                              {{
                                result.development.connections != null
                                  ? result.development.connections
                                  : "-"
                              }}
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="showSiteDevelopment"
                            align="start"
                            justify="center"
                            no-gutters
                          >
                            <v-col cols="6">{{ $t("lines total") }}:</v-col>
                            <v-col cols="6">
                              {{
                                result.development.lines_total != null
                                  ? result.development.lines_total
                                  : "-"
                              }}
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="showSiteDevelopment"
                            align="start"
                            justify="center"
                            no-gutters
                          >
                            <v-col cols="6">{{ $t("lines usable") }}:</v-col>
                            <v-col cols="6">
                              {{
                                result.development.lines_usable != null
                                  ? result.development.lines_usable
                                  : "-"
                              }}
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      vid="profile"
                      :name="$t('profile')"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <v-select
                        v-model="qualiProfileIdx"
                        :items="result.profiles"
                        :label="$t('profile')"
                        :loading="loading"
                        :error-messages="errors"
                        :class="classes"
                        item-text="label"
                        item-value="id"
                        auto-select-first
                        chips
                        :clearable="!isMobile"
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip color="primary">
                            {{
                              item.gfo_variant != null
                                ? item.gfo_variant.title
                                : ""
                            }}
                            - ({{
                              formatBandwidth(
                                item.effective_bw_down || 0,
                                item.effective_bw_up || 0
                              )
                            }}
                            {{ $t("effective") }})
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-chip color="primary">
                            {{
                              item.gfo_variant != null
                                ? item.gfo_variant.title
                                : ""
                            }}
                            - ({{
                              formatBandwidth(
                                item.effective_bw_down || 0,
                                item.effective_bw_up || 0
                              )
                            }}
                            {{ $t("effective") }})
                          </v-chip>
                        </template>
                      </v-select>
                    </validation-provider>
                    <validation-provider
                      v-if="result.timeSlots.length > 0"
                      vid="timeslot"
                      :name="$t('install time slot')"
                      v-slot="{ errors, classes }"
                    >
                      <v-select
                        v-model="qualiTimeSlotIdx"
                        :items="result.timeSlots"
                        :label="$t('install time slot')"
                        :loading="loading"
                        :error-messages="errors"
                        :class="classes"
                        item-text="label"
                        item-value="id"
                        auto-select-first
                        chips
                        :clearable="!isMobile"
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip color="primary">
                            {{ formatDate(item.date) }} -
                            {{
                              item != null && item.label != null
                                ? item.label.split(" ")[1]
                                : ""
                            }}
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-chip color="primary">
                            {{ formatDate(item.date) }} -
                            {{
                              item != null && item.label != null
                                ? item.label.split(" ")[1]
                                : ""
                            }}
                          </v-chip>
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>

    <v-card elevation="0" class="mt-4">
      <v-card-text>
        <v-row align="end" justify="center">
          <v-col :cols="provideBbType ? 6 : 12">
            <date-picker v-model="wishDate" :label="$t('wishdate')" today />
          </v-col>
          <v-col cols="6" v-if="provideBbType">
            <validation-provider
              vid="bbtype"
              :name="$t('broadband type')"
              v-slot="{ valid, dirty, errors }"
            >
              <v-select
                v-model="bbType"
                :items="bbTypes"
                :label="$t('broadband type')"
                :error-messages="errors"
                :success="dirty && valid"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row
          v-if="currentProductId == null && ['ADSL', 'VDSL'].includes(bbType)"
        >
          <v-col cols="6">
            <validation-provider
              vid="linestate"
              :name="$t('line state')"
              v-slot="{ valid, dirty, errors }"
            >
              <v-select
                v-model="lineState"
                :items="lineStates"
                :label="$t('line state')"
                :error-messages="errors"
                :success="dirty && valid"
              >
                <template v-slot:selection="{ item }">
                  {{ $t(item) }}
                </template>
                <template v-slot:item="{ item }">
                  {{ $t(item) }}
                </template>
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              vid="newloop"
              :name="$t('new loop')"
              v-slot="{ valid, changed, errors }"
            >
              <v-switch
                v-model="newLoop"
                :label="$t('new loop')"
                :error-messages="errors"
                :success="changed && valid"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <!--
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="secondary" @click="query" :disabled="loading" text>
          {{ $t("run new query") }}
        </v-btn>
      </v-card-actions>
      -->
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import DatePicker from "@/components/basics/DatePicker";
import formatBandwidth from "@/utils/mixins/formatBandwidth";
import formatDate from "@/utils/mixins/formatDate";
import { convertQualiResults } from "@/utils/convert/convertBbcsQualiResults";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("inetOrder");

export default {
  name: "ProfilesBbcs",
  components: {
    DatePicker,
  },
  mixins: [formatBandwidth, formatDate, showErrors, isMobile],
  props: {
    checking: {
      type: Boolean,
      default: false,
    },
    fire: {
      type: Boolean,
      default: false,
    },
    currentVariantId: {
      type: Number,
      required: false,
      default: null,
    },
    currentProductId: {
      type: Number,
      required: false,
      default: null,
    },
    observerName: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    showSiteDevelopment: false,
    loadingQuali: false,
    loadingVerify: false,
    lastProfileNumber: null,
    bbTypes: ["ADSL", "VDSL"],
    lineStates: ["active", "inactive", "planned", "all"],
  }),
  computed: {
    ...mapState([
      "checkResults",
      "resultIdx",
      "serviceIdx",
      "address",
      "number",
      "oto",
      "plug",
      "bindingId",
    ]),
    ...mapGetters([
      "checkBy",
      "dnType",
      "service",
      "profile",
      "bbcsResultStateOk",
      "bbcsResultStateOkInfo",
      "timeSlot",
    ]),
    qualiResults: {
      ...mapState({ get: "bbcsResults" }),
      ...mapMutations({ set: "setBbcsResults" }),
    },
    qualiResultIdx: {
      ...mapState({ get: "bbcsResultIdx" }),
      ...mapMutations({ set: "setBbcsResultIdx" }),
    },
    qualiProfileIdx: {
      ...mapState({ get: "bbcsProfileIdx" }),
      ...mapMutations({ set: "setBbcsProfileIdx" }),
    },
    qualiTimeSlotIdx: {
      ...mapState({ get: "timeSlotIdx" }),
      ...mapActions({ set: "setTimeSlotIdx" }),
    },
    wishDate: {
      ...mapState({ get: "wishDate" }),
      ...mapMutations({ set: "setWishDate" }),
    },
    bbType: {
      ...mapState({ get: "bbType" }),
      ...mapMutations({ set: "setBbType" }),
    },
    lineState: {
      ...mapState({ get: "lineState" }),
      ...mapMutations({ set: "setLineState" }),
    },
    newLoop: {
      ...mapState({ get: "newLoop" }),
      ...mapMutations({ set: "setNewLoop" }),
    },
    loading() {
      return this.checking || this.loadingQuali || this.loadingVerify;
    },
    bbcsProfileNumber() {
      return this.profile != null ? this.profile.profile_nr : null;
    },
    provideBbType() {
      return (
        this.currentProductId != null && this.bbTypes.includes(this.bbType)
      );
    },
  },
  watch: {
    fire: {
      immediate: true,
      handler(value) {
        if (value) {
          this.qualiResults = [];
          this.query();
        }
      },
    },
    qualiResultIdx(value) {
      this.syncCheckResults();
    },
    qualiProfileIdx(value) {
      this.setProfileIdx(value);
      if (value != null) {
        this.lastProfileNumber = this.bbcsProfileNumber;
      }
    },
    wishDate(value) {
      if (this.timeSlot != null && this.timeSlot.date != value)
        this.qualiTimeSlotIdx = null;
      this.qualiResults = [];
      this.query();
    },
    lineState(value) {
      this.qualiResults = [];
      this.query();
    },
    newLoop(value) {
      this.qualiResults = [];
      this.query();
    },
    bbType(value) {
      this.qualiResults = [];
      this.query();
    },
  },
  methods: {
    ...mapMutations(["setProfileIdx", "setTimeSlotIdx", "setCheckResults"]),
    getSiteDevelopedPercent(result) {
      if (result.development != null)
        if (
          result.development.utilization_units != null &&
          result.development.connections != null &&
          result.development.utilization_units > 0
        )
          return (
            Math.round(
              (result.development.connections /
                result.development.utilization_units) *
                100
            ) + "%"
          );
      return "";
    },
    syncCheckResults() {
      if (this.qualiResultIdx != null && this.resultIdx != null) {
        // replace AccessChecker service with WSG/BBCS service
        var checkResults = this.checkResults;
        checkResults[this.resultIdx].services[this.serviceIdx] =
          this.qualiResults[this.qualiResultIdx];
        this.setCheckResults(checkResults);
      }
    },
    query() {
      if (this.currentProductId) this.bbcsVerify();
      else this.bbcsQuali();
    },
    bbcsQuali() {
      var that = this;
      this.loadingQuali = true;
      var params = {
        dntype: this.dnType,
        bbtype: this.bbType,
        wishdate: this.wishDate || null,
        linestate: this.lineState,
        newloop: this.newLoop,
        sessiontype: this.sessionType,
      };
      if (this.checkBy === "OTO" || this.oto) {
        params.quali_by = "SOCKET";
        params.oto = this.oto;
        params.plug = this.plug || null;
      } else if (this.checkBy === "ADDRESS") {
        params.quali_by = "ADDRESS";
        params.first_name = this.address.first_name || null;
        params.last_name = this.address.last_name || null;
        params.zip = this.address.zip || null;
        params.city = this.address.city || null;
        params.street = this.address.street || null;
        params.house_number = this.address.street_number || null;
        params.building = this.address.building || null;
      } else if (this.checkBy === "NUMBER") {
        params.quali_by = "DN";
        params.dn = this.number || null;
      } else if (this.checkBy === "BINDING") {
        params.quali_by = "BINDING";
        params.bindingid = this.bindingId;
      }
      this.$http
        .post("services/bbcs/quali", params)
        .then((response) => {
          that.setResponse(response.data);
        })
        .catch((error) => {
          if (
            error.invalid() &&
            that.observerName != null &&
            that.$refs[that.observerName] != null
          ) {
            that.$refs[that.observerName].setErrors(error.data);
            that.showErrors(that.$refs.opsStep1.getUnresolvedErrors(error));
          } else {
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
          }
        })
        .finally(function () {
          that.loadingQuali = false;
          that.$emit("fired");
        });
    },
    bbcsVerify() {
      var that = this;
      this.loadingVerify = true;
      this.$http
        .post("services/bbcs/" + this.currentProductId + "/verify", {
          bbtype: this.bbType,
          wishDate: this.wishDate || null,
          profile_number: this.bbcsProfileNumber,
        })
        .then((response) => {
          that.setResponse([response.data]);
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingVerify = false;
          that.$emit("fired");
        });
    },
    setResponse(results) {
      var that = this;
      that.qualiResults = convertQualiResults(results);
      // set quali result index
      that.qualiResultIdx = that.qualiResults.length == 1 ? 0 : null;
      that.qualiResults.forEach(function (service) {
        if (that.bbType && that.bbType === service.name)
          that.qualiResultIdx = service.id;
      });
      // set quali profile index
      that.qualiProfileIdx =
        that.qualiResultIdx != null &&
        that.qualiResults[that.qualiResultIdx] != null &&
        that.qualiResults[that.qualiResultIdx].profiles.length > 0
          ? that.qualiResults[that.qualiResultIdx].profiles.length - 1
          : null;
      if (
        that.qualiResultIdx != null &&
        that.qualiResults[that.qualiResultIdx] != null
      )
        that.qualiResults[that.qualiResultIdx].profiles.forEach(function (
          profile
        ) {
          if (
            that.lastProfileNumber != null &&
            profile.profile_nr == that.lastProfileNumber
          )
            that.qualiProfileIdx = profile.id;
          else if (
            that.lastProfileNumber == null &&
            that.currentVariantId != null &&
            profile.gfo_variant != null &&
            that.currentVariantId === profile.gfo_variant.id
          )
            that.qualiProfileIdx = profile.id;
        });
      // set quali timeslot index
      that.qualiTimeSlotIdx = null;
      // sync check results
      that.setProfileIdx(that.qualiProfileIdx); // force sync
      that.syncCheckResults();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "no results found": "No results found, please run new query!",
    "please run new query": "Please run new query!",
    "bbcs": "xDSL Switzerland",
    "bbcs_fiber": "1 Gbit Fiber Switzerland",
    "bbcs_xgspon": "10 Gbit Fiber Switzerland (XGS-PON)",
    "alex": "Fiber Metro Networks",
    "litex": "Fiber Litecom",
    "max.": "max.",
    "effective": "effective",
    "wishdate": "Wishdate",
    "not available": "not available",
    "available": "available",
    "run new query": "Run new query",
    "broadband type": "Broadband type",
    "transfer point": "Transfer point",
    "address": "Address",
    "yes": "yes",
    "no": "no",
    "switch relevant": "switch relevant",
    "access point": "Access point",
    "install time slot": "Time slot",
    "profile": "Profile",
    "Connected": "connected",
    "plug": "Plug",
    "OTO": "OTO",
    "BEP state": "BEP State",
    "active": "active",
    "Available": "available",
    "contact needed": "Onsite contact needed",
    "NSN": "NSN",
    "max. tech. bandwidth": "max. tech. bandwidth",
    "new loop": "New loop",
    "all": "all",
    "active": "active",
    "inactive": "inactive",
    "planned": "planned",
    "line state": "Line state",
    "site development": "Site development",
    "fully developed": "Fully developed",
    "utilization units": "Utilization units",
    "connections": "Connections",
    "lines total": "Lines total",
    "lines usable": "Lines usable",
    "bbcsResultStateOkInfo": "Number of lines usable 0 / nok - feasibility check after ordering"
  }, 
  "de": {
    "no results found": "Keine Ergebnisse gefunden, bitte führen Sie eine neue Suche aus!",
    "please run new query": "Bitte führen Sie eine neue Suche aus!",
    "bbcs": "xDSL Schweiz",
    "bbcs_fiber": "1 Gbit Glasfaser Schweiz",
    "bbcs_xgspon": "10 Gbit Glasfaser Switzerland (XSG-PON)",
    "alex": "Glasfaser Stadtnetze",
    "litex": "Fiber Litecom",
    "max.": "max.",
    "effective": "effektiv",
    "wishdate": "Wunschdatum",
    "not available": "nicht verfügbar",
    "available": "verfügbar",
    "run new query": "neue Suche starten",
    "pool1": "Public",
    "pool2": "CGNAT (Carrier Grade NAT)",
    "broadband type": "Breitband Typ",
    "transfer point": "Übergabepunkt",
    "address": "Adresse",
    "yes": "ja",
    "no": "nein",
    "switch relevant": "schaltrelevant",
    "access point": "Übergabepunkt",
    "install time slot": "Zeitfenster der Installation",
    "profile": "Profil",
    "Connected": "angeschlossen",
    "plug": "Dose",
    "OTO": "OTO",
    "BEP state": "BEP Status",
    "active": "aktiv",
    "Available": "verfügbar",
    "contact needed": "Vorort Kontakt nötig",
    "NSN": "NSN",
    "max. tech. bandwidth": "max. tech. Bandbreite",
    "new loop": "New loop",
    "all": "alle",
    "active": "aktiv",
    "inactive": "inaktiv",
    "planned": "geplant",
    "line state": "Leitungsstatus",
    "site development": "Ausbau",
    "fully developed": "voll ausgebaut",
    "utilization units": "Nutzungseinheiten",
    "connections": "All-IP Verbindungen",
    "lines total": "Leitungen total",
    "lines usable": "freie Leitungen",
    "bbcsResultStateOkInfo": "keine nutzbaren Leitungen vorhanden - Durchführbarkeitsprüfung nach Bestellung"
  }
}
</i18n>
