<template>
  <validation-provider
    :vid="namePrefix + name"
    :name="label !== null ? label : $t('salutation')"
    :rules="rules !== null ? rules : ''"
    v-slot="{ errors, valid, dirty, classes }"
  >
    <v-select
      v-model="salutation"
      :name="namePrefix + name"
      :items="salutations"
      :label="label !== null ? label : $t('salutation')"
      :hint="hint !== null ? hint : ''"
      :error-messages="errors"
      :success="dirty && valid"
      :disabled="disabled"
      :class="classes"
      item-text="title"
      item-value="code"
      menu-props="auto"
    />
  </validation-provider>
</template>

<script>
export default {
  name: "Salutation",
  props: {
    value: {
      required: true,
    },
    name: {
      type: String,
      default: "salutation",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    namePrefix: {
      type: String,
      default: "",
    },
    noNone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      salutation: this.value,
    };
  },
  computed: {
    salutations() {
      var s = [
        { code: "m", title: this.$i18n.t("Mr.") },
        { code: "f", title: this.$i18n.t("Ms.") },
      ];
      if (this.noNone) return s;
      s.push({ code: "n", title: this.$i18n.t("none") });
      return s;
    },
  },
  watch: {
    salutation: function (value) {
      this.$emit("input", value);
    },
    value: function () {
      this.salutation = this.value;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "none": "None",
    "Mr.": "Mr.",
    "Ms.": "Ms.",
    "salutation": "Salutation"
  }, 
  "de": {
    "none": "Keine",
    "Mr.": "Herr",
    "Ms.": "Frau",
    "salutation": "Anrede"
  }
}
</i18n>
