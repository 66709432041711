const providerQuality = {
    'bbcs_xgspon': 100,
    'bbcs_fiber': 80,
    'alex': 90,
    'litex': 60,
    'bbcs': 20,
};

const serviceQuality = {
    'ADSL': 10,
    'VDSL': 20,
    'BX': 40,
    'XGS': 100,
    5: 50,  // EWB (Bern)
    18: 50, // Zürinet
    25: 50, // Stadtnetz Luzern aka. EWL (Luzern)
    26: 50, // St. Galler Stadtwerke
    29: 50, // Didico (Meilen/Herliberg)
    30: 50, // Stadtwerke Winterthur
    52: 50, // Baden4Net
};

export default {
    methods: {
        sortCheckerResults(results) {
            return [...results].sort(function (a, b) {
                if (a.provider == b.provider) return 0;
                else if (providerQuality.hasOwnProperty(a.provider) && providerQuality.hasOwnProperty(b.provider))
                    return providerQuality[b.provider] - providerQuality[a.provider];
                else return 0;
            }).map(function (result) {
                result.services.sort(function (a, b) {
                    if (a.name == b.name) return 0;
                    else if (serviceQuality.hasOwnProperty(a.name) && serviceQuality.hasOwnProperty(b.name))
                        return serviceQuality[b.name] - serviceQuality[a.name];
                    else return 0;
                })
                return result;
            });
        }
    }
};
