var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-stepper',{attrs:{"alt-labels":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.step > 1,"step":"1","editable":!_vm.ordering}},[_vm._v(" "+_vm._s(_vm.$t("availability"))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t("network"))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.step > 3,"step":"3"}},[_vm._v(" "+_vm._s(_vm.$t("profile"))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v(" "+_vm._s(_vm.$t("options"))+" ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{attrs:{"elevation":"0","color":"transparent"}},[_c('validation-observer',{ref:"opsStep1",attrs:{"disabled":_vm.step !== 1},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-card-text',{staticClass:"mb-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('checker-query',{attrs:{"checking":_vm.checking}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":invalid ||
                    _vm.checking ||
                    _vm.$store.getters.isReadOnly ||
                    _vm.checkIdx == null ||
                    _vm.checkIdx == 4},on:{"click":_vm.step1}},[_vm._v(" "+_vm._s(_vm.$t("check"))+" ")])],1)]}}])})],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{attrs:{"elevation":"0","color":"transparent"}},[_c('validation-observer',{ref:"opsStep2",attrs:{"disabled":_vm.step !== 2},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-card-text',{staticClass:"mb-12"},[_c('checker-results',{attrs:{"checking":_vm.checking}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.resetOrder}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.step = _vm.step - 1}}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid ||
                    _vm.checking ||
                    _vm.service == null ||
                    (_vm.selectOto == null && _vm.otos.length > 0) ||
                    _vm.$store.getters.isReadOnly},on:{"click":_vm.step2}},[_vm._v(" "+_vm._s(_vm.$t("continue"))+" ")])],1)]}}])})],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{attrs:{"elevation":"0","color":"transparent"}},[_c('validation-observer',{ref:"opsStep3",attrs:{"disabled":_vm.step !== 3},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [(_vm.step == 3)?_c('v-card-text',{staticClass:"mb-12"},[(_vm.isBbcs)?_c('profiles-bbcs',{attrs:{"checking":_vm.checking,"observer-name":"opsStep3","fire":_vm.fireBbcsQuery},on:{"fired":function($event){_vm.fireBbcsQuery = false}}}):_c('profiles-metronet')],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.resetOrder}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.step = _vm.step - 1}}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid ||
                    _vm.checking ||
                    _vm.profile == null ||
                    _vm.$store.getters.isReadOnly ||
                    !_vm.bbcsResultStateOk},on:{"click":_vm.step3}},[_vm._v(" "+_vm._s(_vm.$t("continue"))+" ")])],1)]}}])})],1)],1),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('v-card',{attrs:{"elevation":"0","color":"transparent"}},[_c('validation-observer',{ref:"opsStep4",attrs:{"disabled":_vm.step !== 4},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-card-text',{staticClass:"mb-12"},[_c('order-summary',{staticClass:"mb-4"}),_c('create-order')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.resetOrder}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.ordering},on:{"click":function($event){_vm.step = _vm.step - 1}}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid ||
                    _vm.ordering ||
                    _vm.$store.getters.isReadOnly ||
                    _vm.service == null,"loading":_vm.ordering},on:{"click":_vm.step4}},[_vm._v(" "+_vm._s(_vm.$t("order"))+" ")])],1)]}}])})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }