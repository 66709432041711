<template>
  <validation-provider
    :vid="prefix + name"
    :name="label !== null ? label : $t('country')"
    :rules="rules !== null ? rules : ''"
    v-slot="{ errors, valid, dirty, classes }"
  >
    <v-autocomplete
      v-model="country"
      :id="id"
      :name="prefix + name"
      :items="countries"
      :label="label !== null ? label : $t('country')"
      :hint="hint !== null ? hint : ''"
      :error-messages="errors"
      :success="dirty && valid"
      :filter="customFilter"
      :disabled="disabled"
      :class="classes"
      @input="$emit('input', $event)"
      item-text="native"
      item-value="code"
      cache-items
    >
      <template v-slot:selection="data">
        <template>
          <v-list-item-avatar>
            <country-flag :country="data.item.code.toLowerCase()" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.native }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-avatar>
            <country-flag :country="data.item.code.toLowerCase()" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.native }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ data.item.name }} ({{ data.item.code }})</v-list-item-subtitle
            >
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </validation-provider>
</template>

<script>
import countries from "countries-list";

export default {
  name: "CountrySelect",
  props: {
    value: {
      type: String
    },
    id: {
      type: String,
      default: "country"
    },
    name: {
      type: String,
      default: "country"
    },
    label: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: null
    },
    rules: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      country: this.value,
      countries: this.getCountries(countries.countries)
    };
  },
  watch: {
    value: function() {
      this.country = this.value;
    }
  },
  methods: {
    updateValue: function(value) {
      this.$emit("input", value);
    },
    getCountries: function(countries) {
      return Object.keys(countries).map(function(code) {
        return {
          code: code,
          native: countries[code].native,
          name: countries[code].name,
          continent: countries[code].continent
        };
      });
    },
    customFilter(item, queryText, itemText) {
      const name = item.name.toLowerCase();
      const native = item.native.toLowerCase();
      const code = item.code.toLowerCase();
      const q = queryText.toLowerCase();

      return (
        name.indexOf(q) > -1 || native.indexOf(q) > -1 || code.indexOf(q) > -1
      );
    }
  }
};
</script>

<i18n>
{
  "en": {
   "country": "Country"
  }, 
  "de": {
    "country": "Land"
  }
}
</i18n>
